import {
  ApolloClient,
  ApolloLink,
  Observable,
  Operation
} from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import * as Sentry from "@sentry/gatsby";
import { createUploadLink } from "apollo-upload-client";
import { navigate } from "gatsby";
import { GraphQLError } from "graphql";
import fetch from "isomorphic-fetch";

import possibleTypes from "../../../possibleTypes.json";

import { hasAuthorizationFailedErr, refreshToken } from "~lib/authentication";
import { RESUME_USER } from "~lib/keyNamesOfLocalStorage";
import { removeLocalStorageUser } from "~lib/localStorage";

interface TokenErrorState {
  tokenError: boolean;
  forceLogout: boolean;
}

const redirectForApolloError = (): void => {
  const tokenErrorAndForceLogoutState: TokenErrorState = {
    tokenError: true,
    forceLogout: true
  };
  navigate("/signin", {
    state: tokenErrorAndForceLogoutState
  });
};

const promiseToObservable = <T>(promise: Promise<T>): Observable<T> =>
  new Observable<T>(subscriber => {
    promise
      .then(value => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      })
      .catch(() => {
        removeLocalStorageUser();
        const domain = process.env.HOSTNAME || "resume.com";
        const jwtDomain = domain.replace("www.", "");
        const path = "/";
        document.cookie =
          "jwt=; expires=" +
          +new Date() +
          "; domain=" +
          `.${jwtDomain}` +
          "; path=" +
          path;
        redirectForApolloError();
      });
  });

// FragmentMatcher, HeuristicFragmentMatcher, and IntrospectionFragmentMatcher have all been removed.
// We recommend using the InMemoryCache’s possibleTypes option instead.
const cache = new InMemoryCache({ possibleTypes });

const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const errors = response.errors as readonly GraphQLError[] | undefined;

    if (errors) {
      const isRegularUser = JSON.parse(localStorage.getItem(RESUME_USER))
        ?.accountEmail;

      if (hasAuthorizationFailedErr({ graphQLErrors: errors })) {
        if (isRegularUser) {
          promiseToObservable(refreshToken()).subscribe(() => {
            forward(operation);
          });
        } else {
          redirectForApolloError();
        }
      }
    }

    return response;
  });
});

const requestLink = new ApolloLink((operation: Operation, forward) => {
  const { operationName, variables } = operation;
  if (typeof Sentry === `object`) {
    Sentry.withScope(scope => {
      scope.clear();

      scope.setExtra("operationName", operationName);

      Object.keys(variables || {}).forEach(key =>
        scope.setExtra(key, variables[key])
      );
    });
  }
  return forward(operation);
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    requestLink,
    createUploadLink({
      uri: process.env.GRAPHQL_ENDPOINT,
      credentials: "include",
      fetch
    })
  ]),
  cache
});
