import { FetchResult } from "@apollo/client";
import { cloneDeep } from "lodash-es";

import { client } from "./client";

import {
  resumeMutations,
  resumeQueries
} from "~apollo/graphql-gateway/queries/resumeQueries";
import { Resume, IntersectionItem } from "~lib/commonType/resume";
import { isObjectLogicEmpty, updateResumeSectionIDs } from "~lib/objectUtils";

const { getAllResumes } = resumeQueries;

const {
  addEducationItem,
  updateEducationItem,
  removeEducationItem,
  addEmploymentItem,
  updateEmploymentItem,
  removeEmploymentItem,
  addSkillItem,
  updateSkillItem,
  removeSkillItem,
  addLanguageItem,
  updateLanguageItem,
  removeLanguageItem,
  updateContactDetails,
  updateSummary,
  updateSectionName,
  updateSectionOrder,
  sortSectionItemsQuery,
  create,
  deleteResume,
  deleteCustomSection,
  newCustomSection,
  updateCustom,
  update: updateResumeMutation,
  sendResumeEmail,
  moveGuestResume,
  restart,
  duplicate,
  deactivateSection,
  activateSection,
  addCertificateItem,
  updateCertificateItem,
  removeCertificateItem,
  addAwardItem,
  updateAwardItem,
  removeAwardItem,
  addAnalyticsTimeExported,
  updateNextStepServicesStatus,
  updateShareStatus,
  upsertResume,
  uploadResume
} = resumeMutations;

export const UPDATE = {
  education: {
    query: updateEducationItem,
    key: "updateEducationItem"
  },
  employment: {
    query: updateEmploymentItem,
    key: "updateEmploymentItem"
  },
  skills: {
    query: updateSkillItem,
    key: "updateSkillItem"
  },
  languages: {
    query: updateLanguageItem,
    key: "updateLanguageItem"
  },
  contactDetails: {
    query: updateContactDetails,
    key: "updateContactDetails"
  },
  summary: {
    query: updateSummary,
    key: "updateSummary"
  },
  customSection: {
    query: updateCustom,
    key: "updateCustom"
  },
  certificates: {
    query: updateCertificateItem,
    key: "updateCertificateItem"
  },
  awards: {
    query: updateAwardItem,
    key: "updateAwardItem"
  }
};

export const ADD = {
  education: {
    query: addEducationItem,
    key: "addEducationItem"
  },
  employment: {
    query: addEmploymentItem,
    key: "addEmploymentItem"
  },
  skills: {
    query: addSkillItem,
    key: "addSkillItem"
  },
  languages: {
    query: addLanguageItem,
    key: "addLanguageItem"
  },
  certificates: {
    query: addCertificateItem,
    key: "addCertificateItem"
  },
  awards: {
    query: addAwardItem,
    key: "addAwardItem"
  }
};

export const REMOVE = {
  education: {
    query: removeEducationItem,
    key: "removeEducationItem"
  },
  employment: {
    query: removeEmploymentItem,
    key: "removeEmploymentItem"
  },
  skills: {
    query: removeSkillItem,
    key: "removeSkillItem"
  },
  languages: {
    query: removeLanguageItem,
    key: "removeLanguageItem"
  },
  certificates: {
    query: removeCertificateItem,
    key: "removeCertificateItem"
  },
  awards: {
    query: removeAwardItem,
    key: "removeAwardItem"
  }
};

export const createResume = async (
  templateId: string,
  title: string
): Promise<Resume> => {
  try {
    const { data } = await client.mutate({
      mutation: create,
      variables: {
        template: templateId,
        title: title
      },
      refetchQueries: [
        {
          query: getAllResumes
        }
      ]
    });

    if (data) {
      return data.Resume.create;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
  return null;
};

export const mutateSection = (
  sectionType: string,
  sectionId: string,
  resumeId: string
) => async (input: Record<string, any>): Promise<FetchResult<any>> => {
  const mutationQuery = (UPDATE[sectionType] || {}).query;

  if (!mutationQuery) {
    return null;
  }

  try {
    await client.mutate({
      mutation: mutationQuery,
      variables: {
        resumeId,
        sectionId: sectionId,
        ...input
      },
      refetchQueries: [
        {
          query: getAllResumes
        }
      ]
    });
  } catch (e) {
    return e;
  }
  return null;
};

export const mutateSectionItems = (
  sectionType: string,
  resumeId: string
) => async (input: Record<string, any>, itemId?: string): Promise<string> => {
  let mutationQuery = null;
  let mutationKey = null;
  if (itemId) {
    mutationQuery = (UPDATE[sectionType] || {}).query;
    mutationKey = (UPDATE[sectionType] || {}).key;
  } else {
    mutationQuery = (ADD[sectionType] || {}).query;
    mutationKey = (ADD[sectionType] || {}).key;
  }
  if (!mutationQuery || !mutationKey) {
    return null;
  }

  if (isObjectLogicEmpty(input) && !itemId) return;

  try {
    const { data } = await client.mutate({
      mutation: mutationQuery,
      variables: {
        resumeId,
        ...input,
        id: itemId
      },
      refetchQueries: [
        {
          query: getAllResumes
        }
      ]
    });
    if (!itemId && data) {
      return getLatestItemId(data.Resume[mutationKey], sectionType);
    }
  } catch (e) {
    return e;
  }
  return null;
};

export const sortSectionItems = (
  sectionType: string,
  resumeId: string
) => async (orderedItems: IntersectionItem[]): Promise<FetchResult<any>> => {
  const orderedItemIds = orderedItems.map(item => item.id);

  return client.mutate({
    mutation: sortSectionItemsQuery,
    variables: {
      sectionType,
      resumeId,
      itemIds: orderedItemIds
    },
    refetchQueries: [
      {
        query: getAllResumes
      }
    ]
  });
};

export const removeSectionItems = (
  sectionType: string,
  resumeId: string
) => async (itemId: string): Promise<FetchResult<any>> => {
  const mutationQuery = (REMOVE[sectionType] || {}).query;
  if (!mutationQuery) {
    return null;
  }

  return client.mutate({
    mutation: mutationQuery,
    variables: {
      resumeId,
      id: itemId
    }
  });
};

export async function updateResume(
  resumeId: string,
  template: string,
  title?: string,
  desiredTitle?: string
): Promise<{ success: boolean }> {
  const response = { success: false };
  try {
    const res = await client.mutate({
      mutation: updateResumeMutation,
      variables: {
        id: resumeId,
        template,
        title,
        desiredTitle
      }
    });
    if (res.data && res.data.Resume && res.data.Resume.update) {
      updateResumeSectionIDs(res.data.Resume.update);
      response.success = true;
    }
    return response;
  } catch (e) {
    return response;
  }
}

export const getLatestItemId = (
  resume: Resume,
  sectionType: string
): string => {
  if (!resume) return null;
  if (!sectionType) return null;
  const section = (resume.sections || []).find(item => {
    return item.title === sectionType;
  });
  if (!section || !section.items) return null;
  return (section.items[section.items.length - 1] || {}).id;
};

export async function removeResume(
  resumeId: string
): Promise<FetchResult<any>> {
  if (resumeId) {
    try {
      return client.mutate({
        mutation: deleteResume,
        variables: {
          id: resumeId
        },
        refetchQueries: [
          {
            query: getAllResumes
          }
        ]
      });
    } catch (e) {
      return e;
    }
  }
  return null;
}

export async function renameSection(
  resumeId: string,
  sectionId: string,
  newName: string
): Promise<{ success: boolean }> {
  const response = { success: false };
  try {
    const res = await client.mutate({
      mutation: updateSectionName,
      variables: {
        resumeId,
        sectionId: sectionId,
        newName: newName
      }
    });
    if (res.data && res.data.Resume && res.data.Resume.updateSectionName) {
      updateResumeSectionIDs(res.data.Resume.updateSectionName);
      response.success = true;
    }
    return response;
  } catch (e) {
    return response;
  }
}

export async function sortSections(
  resumeId: string,
  sectionIds: string[],
  allSectionIds: string[]
): Promise<{ success: boolean }> {
  const response = { success: false };
  try {
    const res = await client.mutate({
      mutation: updateSectionOrder,
      variables: {
        resumeId,
        sectionIds: sectionIds,
        allSectionIds
      }
    });
    if (res.data && res.data.Resume && res.data.Resume.sortSections) {
      updateResumeSectionIDs(res.data.Resume.sortSections);
      response.success = true;
    }
    return response;
  } catch (e) {
    return response;
  }
}

export async function createCustomSection(
  resumeId: string,
  name: string
): Promise<{ success: boolean; data: Resume }> {
  let response = { success: false, data: null };
  try {
    const res = await client.mutate({
      mutation: newCustomSection,
      variables: {
        resumeId,
        name
      }
    });

    if (res.data && res.data.Resume && res.data.Resume.createCustomSection) {
      updateResumeSectionIDs(res.data.Resume.createCustomSection);
      response = { data: res.data.Resume.createCustomSection, success: true };
    }

    return response;
  } catch (e) {
    return response;
  }
}

export async function removeCustomSection(
  resumeId: string,
  sectionId: string
): Promise<{ success: boolean; data: any }> {
  let response = { success: false, data: null };
  if (resumeId && sectionId) {
    try {
      const res = await client.mutate({
        mutation: deleteCustomSection,
        variables: {
          resumeId,
          sectionId
        }
      });

      if (res.data && res.data.Resume && res.data.Resume.removeCustomSection) {
        const resumeData = cloneDeep(res.data.Resume.removeCustomSection);
        updateResumeSectionIDs(resumeData);
        response = { data: resumeData, success: true };
      }
      return response;
    } catch (e) {
      return response;
    }
  }
  return response;
}

export async function sendResume(
  resumeId: string,
  recipientEmail: string
): Promise<FetchResult<any>> {
  try {
    return await client.mutate({
      mutation: sendResumeEmail,
      variables: {
        resumeId,
        recipientEmail
      }
    });
  } catch (e) {
    return e;
  }
}

export async function addAnalytics(
  resumeId: string
): Promise<FetchResult<any>> {
  try {
    return await client.mutate({
      mutation: addAnalyticsTimeExported,
      variables: {
        resumeId
      }
    });
  } catch (e) {
    return e;
  }
}

export async function moveGuestResumesToAccount(
  guestUserId: string
): Promise<any> {
  try {
    return await client.mutate({
      mutation: moveGuestResume,
      variables: {
        guestUserId
      },
      refetchQueries: [
        {
          query: getAllResumes
        }
      ]
    });
  } catch (e) {
    return e;
  }
}

export async function restartResume(
  resumeId: string,
  title: string,
  templateId: string
): Promise<string> {
  if (resumeId) {
    try {
      const { data } = await client.mutate({
        mutation: restart,
        variables: {
          resumeId,
          title,
          template: templateId
        }
      });
      if (data) {
        return data.Resume.restart.id;
      }
    } catch (e) {
      return e;
    }
  }
  return null;
}

export const duplicateResume = async (
  id: string,
  newTitle: string
): Promise<{
  success: boolean;
  data: { id: string };
  error: { graphQLErrors: Array<Record<string, any>> };
}> => {
  const response = { success: false, error: null, data: { id: null } };
  if (id) {
    try {
      const { data } = await client.mutate({
        mutation: duplicate,
        variables: {
          id,
          newTitle
        },
        refetchQueries: [
          {
            query: getAllResumes
          }
        ]
      });
      if (data && data.Resume && data.Resume.duplicate) {
        response.success = true;
        response.data.id = data.Resume.duplicate.id;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const hideSection = async (
  resumeId: string,
  sectionId: string,
  type: string
): Promise<{
  success: boolean;
}> => {
  const response = { success: false, error: null };
  if (resumeId && (sectionId || type)) {
    try {
      const { data } = await client.mutate({
        mutation: deactivateSection,
        variables: {
          resumeId,
          sectionId,
          type
        },
        refetchQueries: [
          {
            query: getAllResumes
          }
        ]
      });
      if (data) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const showSection = async (
  resumeId: string,
  sectionId: string,
  type: string
): Promise<{
  success: boolean;
}> => {
  const response = { success: false, error: null };
  if (resumeId && (sectionId || type)) {
    try {
      const { data } = await client.mutate({
        mutation: activateSection,
        variables: {
          resumeId,
          sectionId,
          type
        },
        refetchQueries: [
          {
            query: getAllResumes
          }
        ]
      });
      if (data) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const setNextStepServicesStatus = async ({
  resumeId,
  option1,
  option2,
  option3
}: {
  resumeId: string;
  option1: boolean;
  option2: boolean;
  option3: boolean;
}): Promise<{
  success: boolean;
}> => {
  const response = { success: false, error: null };
  if (resumeId) {
    try {
      const { data } = await client.mutate({
        mutation: updateNextStepServicesStatus,
        variables: {
          resumeId,
          option1,
          option2,
          option3
        },
        refetchQueries: [
          {
            query: getAllResumes
          }
        ]
      });
      if (data) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const updateResumeShareStatus = async (
  resumeId: string,
  isShared: boolean
): Promise<{
  success: boolean;
  error?: string;
}> => {
  const response = { success: false, error: null };
  if (resumeId) {
    try {
      const { data } = await client.mutate({
        mutation: updateShareStatus,
        variables: {
          resumeId,
          isShared
        }
      });
      if (data) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const createResumeFromSample = async (
  resumeData: string
): Promise<Resume> => {
  try {
    const { data } = await client.mutate({
      mutation: upsertResume,
      variables: {
        resumeData
      },
      refetchQueries: [
        {
          query: getAllResumes
        }
      ]
    });

    if (data) {
      return data?.Resume?.upsert;
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
  return null;
};

export const createResumeByUpload = async (
  resumeForUpload: File
): Promise<{
  success: boolean;
  data: { resumeId: string };
  error: { message: string };
}> => {
  const response = { success: false, error: null, data: null };

  try {
    const { data } = await client.mutate({
      mutation: uploadResume,
      variables: {
        file: resumeForUpload
      }
    });
    if (data) {
      response.success = true;
      response.data = data.Resume.uploadResume;
      return response;
    }
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};
