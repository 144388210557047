import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

import { COVER_LETTER_FRAGMENT } from "~apollo/graphql-gateway/fragments/coverLetterFragments";

const getCoverLetter: DocumentNode = gql`
  query($id: ID) {
    CoverLetter(filter: { id: $id }) {
      ...CoverLetterFragment
    }
  }

  ${COVER_LETTER_FRAGMENT}
`;

const getAllCoverLetters: DocumentNode = gql`
  query getAll {
    CoverLetter {
      getAll {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const updateContactDetails: DocumentNode = gql`
  mutation updateContactDetails(
    $coverLetterId: ID
    $email: String
    $phone: String
    $firstName: String
    $lastName: String
    $jobCategory: String
  ) {
    CoverLetter(id: $coverLetterId) {
      updateContactDetails(
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        jobCategory: $jobCategory
      ) {
        ...CoverLetterFragment
      }
    }
  }

  ${COVER_LETTER_FRAGMENT}
`;

const update: DocumentNode = gql`
  mutation($id: ID!, $body: String, $title: String, $template: String) {
    CoverLetter(id: $id) {
      update(body: $body, title: $title, template: $template) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const updateFooter: DocumentNode = gql`
  mutation($id: ID!, $footer: String) {
    CoverLetter(id: $id) {
      updateFooter(footer: $footer) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const create: DocumentNode = gql`
  mutation($template: String!) {
    CoverLetter {
      create(template: $template) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const deleteCoverLetter: DocumentNode = gql`
  mutation deleteCoverLetter($id: ID) {
    CoverLetter {
      remove(id: $id) {
        message
      }
    }
  }
`;

const sendCoverLetterEmail: DocumentNode = gql`
  mutation sendCoverLetterEmail($coverLetterId: ID, $recipientEmail: String) {
    CoverLetter {
      sendCoverLetterEmail(
        coverLetterId: $coverLetterId
        recipientEmail: $recipientEmail
      ) {
        message
      }
    }
  }
`;

const restart: DocumentNode = gql`
  mutation RestartCoverLetter(
    $coverLetterId: ID
    $title: String
    $template: String
  ) {
    CoverLetter {
      restart(id: $coverLetterId, title: $title, template: $template) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const moveGuestCoverLetter: DocumentNode = gql`
  mutation moveGuestCoverLettersToAccount($guestUserId: String!) {
    CoverLetter {
      moveGuestCoverLettersToAccount(guestUserId: $guestUserId) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const duplicate: DocumentNode = gql`
  mutation duplicate($id: ID!, $title: String) {
    CoverLetter {
      duplicate(id: $id, title: $title) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

const upsertCoverLetter: DocumentNode = gql`
  mutation upsert($coverLetterData: String) {
    CoverLetter {
      upsert(coverLetterData: $coverLetterData) {
        ...CoverLetterFragment
      }
    }
  }
  ${COVER_LETTER_FRAGMENT}
`;

export const coverLetterQueries = {
  getCoverLetter,
  getAllCoverLetters
};

export const coverLetterMutations = {
  updateContactDetails,
  update,
  updateFooter,
  create,
  deleteCoverLetter,
  sendCoverLetterEmail,
  restart,
  moveGuestCoverLetter,
  duplicate,
  upsertCoverLetter
};
