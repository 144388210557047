export const ERROR_CODES = {
  UNEXPECTED_ERROR: "There was a server error on our end. Please try again.",
  // account
  EMAIL_ALREADY_EXISTS_ERROR: "The e-mail provided is already in use.",
  EMAIL_NOT_FOUND: "We couldn't find an account with that email address.",
  EMPTY_EMAIL_ERROR: "Email is required.",
  EMPTY_FIRST_NAME_ERROR: "First name cannot be empty.",
  EMPTY_LAST_NAME_ERROR: "Last name cannot be empty.",
  INVALID_EMAIL_ERROR: "Please enter a valid e-mail.",
  UPDATE_ACCOUNT_ERROR: "Unsuccessful update, please try again.",
  TOKEN_NOT_FOUND_ERROR: "Token doesn't exist.",
  EMAIL_NOT_ALLOWED_ON_DEV: "Email not allowed.",
  FIRSTNAME_AND_LASTNAME_LENGTH_ERROR:
    "First name and last name should be at least 2 characters.",
  SEND_EMAIL_LIMIT_ERROR:
    "You've reached the maximum amount of exports per day. Please try again in 24 hours.",
  OPERATION_TIMES_EXCEEDED:
    "You've reached the maximum amount of merge accounts per hour. Please try again in an hour later.",
  ALREADY_CONNECTED_WITH_INDEED:
    "This account has already connected with Indeed.",
  // resume and cover letter
  DELETE_RESUME_OR_COVER_LETTER_ERROR:
    "There was a problem deleting your resume or cover letter. Please try again.",
  DOWNLOAD_ERROR: "Please input at least 35 words.",
  MAXIMUM_RESUME_ERROR:
    "You can save up to 5 resumes. Please download if needed and delete to create new resume. ",
  MAXIMUM_LIMIT_RESUME_ERROR:
    "You have reached the maximum number of resumes allowed (50). Please delete one or more to make a new one.",
  MAXIMUM_LIMIT_COVER_LETTER_ERROR:
    "You have reached the maximum number of cover letters allowed (50). Please delete one or more to make a new one.",
  NETWORK_ERROR: "Network error. Please try again.",
  RENAME_RESUME_OR_COVER_LETTER_ERROR:
    "There was a problem renaming your resume or cover letter. Please try again.",
  RENAME_RESUME_ERROR: "Resume name maximum is 60 characters.",
  RENAME_COVER_LETTER_ERROR: "Cover letter name maximum is 60 characters.",
  CUSTOM_NAME_ERROR: "Custom section name maximum is 60 characters.",
  SECTION_NAME_LENGTH_ERROR: "Rename section name maximum is 60 characters.",
  RENAME_SECTION_ERROR:
    "There was a server error renaming the section. Please try again.",
  SKILL_EMPTY_ERROR: "Skill can't be blank.",
  LANGUAGE_EMPTY_ERROR: "Language can't be blank.",
  AWARD_EMPTY_ERROR: "Award can't be blank.",
  CERTIFICATE_EMPTY_ERROR: "Certificate can't be blank.",
  SKILL_LENGTH_ERROR: "Skills maximum is 60 characters.",
  LANGUAGE_LENGTH_ERROR: "Languages maximum is 60 characters.",
  AWARD_LENGTH_ERROR: "Awards maximum is 60 characters.",
  CERTIFICATE_LENGTH_ERROR: "Certificates maximum is 60 characters.",
  // edit section modal
  LOCATION_LENGTH_ERROR: "Location is too long (maximum is 60 characters).",
  VALID_LINK: "Please enter a valid url. ex: https://www.example.com.",
  // Date
  DATE_ERROR: "Invalid date.",
  MINIMAL_DATE_ERROR: "Date should not be before minimal date.",
  MAXIMAL_DATE_ERROR: "Date should not be after maximal date.",
  END_BEFORE_START_ERROR: "End date should not be before start date.",

  UPLOAD_ERROR: "Oops: invalid file type or size",

  // desired job title modal
  DESIRED_JOB_TITLE_EMPTY_ERROR: "Please input desired job title",
  JOB_DESCRIPTION_EMPTY_ERROR: "Please input job description"
};
