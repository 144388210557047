import { client } from "./client";

import { userMutations } from "~apollo/graphql-gateway/queries/userQueries";
import {
  hasAlreadyExistsErr,
  hasNotFoundErr,
  logout
} from "~lib/authentication";
import { ResponseObj } from "~lib/commonType/apollo";
import { emailValidator } from "~lib/validators";

const {
  guestVerify,
  createGuestUser: createGuest,
  updateUserInfo,
  deleteUser,
  getUserInfo,
  confirmEmailNotExist,
  connectOtherUserAccounts,
  signInWithIndeed,
  connectUserAccount,
  connectWithIndeed,
  contactUs,
  dumpData,
  ShareResumeToIndeed,
  getIndeedProfile
} = userMutations;

export const confirmEmailDoesNotExist = async (
  accountEmail: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };

  if (accountEmail && emailValidator(accountEmail)) {
    try {
      const { data } = await client.mutate({
        mutation: confirmEmailNotExist,
        variables: {
          accountEmail
        }
      });

      if ((((data || {}).User || {}).confirmEmailDoesNotExist || {}).message) {
        response.success = true;
        return response;
      }
      return null;
    } catch (e) {
      if (hasAlreadyExistsErr(e)) {
        response.error = "ALREADY_EXISTS";
      } else {
        response.error = "UNKNOWN";
      }
      return response;
    }
  } else {
    response.error = "EMPTY";
    return response;
  }
};

export const verifyGuestUser = async (
  accountEmail: string,
  firstName: string,
  lastName: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };

  if (accountEmail && firstName && lastName) {
    try {
      const { data } = await client.mutate({
        mutation: guestVerify,
        variables: {
          accountEmail,
          firstName,
          lastName
        }
      });
      const userData = data && data.User && data.User.guestVerify;

      response.data = userData;
      response.success = !!userData;

      return response;
    } catch (e) {
      console.log("verifyGuestUser error", e);
      if (hasNotFoundErr(e)) {
        response.error = "NOT_FOUND";
      } else if (hasAlreadyExistsErr(e)) {
        response.error = "ALREADY_EXISTS";
      } else {
        response.error = e;
      }
      return response;
    }
  } else {
    response.error = "EMPTY";
    return response;
  }
};

export const createGuestUser = async (): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };

  try {
    const { data } = await client.mutate({
      mutation: createGuest
    });

    if (data && data.User && data.User.createGuest) {
      const createGuestResponse = data.User.createGuest || {};

      let userId = "";
      if (createGuestResponse.user && createGuestResponse.user.id) {
        userId = createGuestResponse.user.id;
        response.user = createGuestResponse.user;
        // to maintenance both versions
        if (createGuestResponse.magicToken) {
          response.magicToken = createGuestResponse.magicToken;
        }
      } else if (createGuestResponse.id) {
        // to maintenance both versions
        userId = createGuestResponse.id;
        response.user = createGuestResponse;
      }
      if (userId) {
        response.success = true;
      }
    }

    return response;
  } catch (e) {
    response.error = "UNKNOWN";
    return response;
  }
};

export const updateUser = async (
  accountEmail: string,
  firstName: string,
  lastName: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  try {
    const { data } = await client.mutate({
      mutation: updateUserInfo,
      variables: {
        accountEmail,
        firstName,
        lastName
      }
    });
    const userData = data && data.User && data.User.update;
    response.success = !!userData;
    response.data = userData;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};

export const removeUser = async (userID: string): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  try {
    await client.mutate({
      mutation: deleteUser,
      variables: {
        userID
      }
    });
    await logout();
    response.success = true;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};

export const getUser = async (userID: string): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  try {
    const { data } = await client.query({
      query: getUserInfo,
      variables: {
        id: userID
      }
    });
    const userData = data && data.User;
    response.success = !!userData;
    response.data = userData;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};

export const sendConnectAccountEmail = async (
  accountEmail: string,
  userID: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  if (accountEmail && emailValidator(accountEmail)) {
    try {
      const { data } = await client.mutate({
        mutation: connectOtherUserAccounts,
        variables: {
          accountEmail,
          userID
        }
      });

      if ((((data || {}).User || {}).connectOtherUserAccounts || {}).message) {
        response.success = true;
        return response;
      }
      return null;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const logInWithIndeed = async (code: string): Promise<ResponseObj> => {
  const response: ResponseObj = {
    success: false,
    error: null,
    magicToken: null,
    accessToken: null,
    refreshToken: null
  };
  if (code) {
    try {
      const { data } = await client.mutate({
        mutation: signInWithIndeed,
        variables: {
          code
        }
      });
      if (data?.User?.signInWithIndeed?.magicToken) {
        response.success = true;
        response.magicToken = data.User.signInWithIndeed.magicToken;
        response.indeedEmail = data.User.signInWithIndeed.indeedEmail;
        response.accessToken = data.User.signInWithIndeed.accessToken;
        response.refreshToken = data.User.signInWithIndeed.refreshToken;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const mergeUserAccount = async (
  userID: string,
  connectToken: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, user: null, error: null };

  if (connectToken) {
    try {
      const { data } = await client.mutate({
        mutation: connectUserAccount,
        variables: {
          userID,
          connectToken
        }
      });
      if (data?.User?.connectUserAccount?.id) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const connectAccountWithIndeed = async (
  userID: string,
  code: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  if (userID && code) {
    try {
      const { data } = await client.mutate({
        mutation: connectWithIndeed,
        variables: {
          userID,
          code
        }
      });
      if (data?.User?.connectWithIndeed?.message) {
        response.success = true;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const dumpDataAndRemoveUser = async (
  userID: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  if (userID) {
    try {
      await client.mutate({
        mutation: dumpData,
        variables: {
          userID
        }
      });
      await logout();
      response.success = true;
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const sendContactEmail = async (
  userEmail: string,
  fullName: string,
  subject: string,
  message: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  try {
    await client.mutate({
      mutation: contactUs,
      variables: {
        userEmail,
        fullName,
        subject,
        message
      }
    });
    response.success = true;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};

export const shareWithIndeed = async (
  resumeId: string,
  isPrivate: boolean,
  sharedResumeHash: string
): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null };
  try {
    await client.mutate({
      mutation: ShareResumeToIndeed,
      variables: {
        id: resumeId,
        appId: "RESCOM",
        isPrivate,
        sharedResumeHash
      }
    });
    response.success = true;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};

export const getIndeedProfileStatus = async (): Promise<ResponseObj> => {
  const response: ResponseObj = { success: false, error: null, profile: null };
  try {
    const { data } = await client.mutate({
      mutation: getIndeedProfile
    });

    if (data?.User?.getIndeedProfile?.profile?.resume?.id) {
      response.profile = data.User.getIndeedProfile.profile;
    }
    response.success = true;
    return response;
  } catch (e) {
    response.error = e;
    return response;
  }
};
