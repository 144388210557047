import { cloneDeep } from "lodash-es";

import { InputValues } from "~components/modalWeb/commonEditCoverLetterModal";
import {
  FlowProps,
  InputsProps
} from "~components/modalWeb/commonEditSectionModal/resumeSectionsFlow";
import {
  Resume,
  Section,
  IntersectionItem,
  ContactDetails,
  ChipInputItem
} from "~lib/commonType/resume";
import {
  SECTIONS_HAVE_ITEMS,
  AWARDS_SECTION,
  CERTIFICATES_SECTION,
  EDUCATION_SECTION,
  EMPLOYMENT_SECTION,
  LANGUAGES_SECTION,
  SKILLS_SECTION
} from "~lib/constants";
import { deepEqual, isObjectLogicEmpty } from "~lib/objectUtils";
import { returnResumePlaceholders } from "~lib/resumePlaceholders";
import { capitalize } from "~lib/stringProcessing";

type SectionConfig = {
  [key: string]: {
    notSection?: boolean;
    noPlural?: boolean;
    displayName?: string;
    displayAsSingleConcatItem?: boolean;
    displayAsSingleItem?: boolean;
    defaultName?: string;
  };
};

function getSectionConfig(name: string): SectionConfig[string] {
  const section_config = {
    contactDetails: { notSection: true, noPlural: true, displayName: "header" },
    education: { noPlural: true },
    employment: { displayName: "experience" },
    skills: {
      displayAsSingleConcatItem: true,
      noPlural: true,
      defaultName: "skills"
    },
    languages: {
      displayAsSingleConcatItem: true,
      noPlural: true,
      defaultName: "languages"
    },
    summary: {
      displayAsSingleItem: true,
      noPlural: true,
      defaultName: "summary"
    },
    customSection: {
      displayAsSingleItem: true,
      noPlural: true,
      defaultName: "customSection",
      displayName: "Custom Section"
    },
    certificates: {
      displayAsSingleConcatItem: true,
      noPlural: true,
      defaultName: "certificates"
    },
    awards: {
      displayAsSingleConcatItem: true,
      noPlural: true,
      defaultName: "awards"
    }
  };
  // if there's no config, name is probably an ID, which is customSection
  return section_config[name] || section_config["customSection"];
}

export function injectValueInInput(
  specs: FlowProps,
  resume: Resume,
  sectionType: string,
  itemId: string
): FlowProps {
  const { sections } = resume;
  let section: Section = null;
  let item: IntersectionItem = null;
  const { inputs } = specs;

  if (sectionType) {
    if (isNotASection(sectionType)) {
      section = resume[sectionType];
      item = section;
    } else {
      section = getSection(sectionType, sections);
      if (itemId && section && section.items) {
        item = section.items.find(itemCur => itemCur.id === itemId);
      }
    }
  }

  const correctedItem = correctItem(item);

  // Note id is the key of the property.
  if (Array.isArray(inputs)) {
    for (let k = 0; k < inputs.length; k++) {
      injectValueIntoInput(inputs[k], correctedItem, section, sectionType);
    }
  } else {
    injectValueIntoInput(inputs, correctedItem, section, sectionType);
  }
  return specs;
}

function correctItem(item: IntersectionItem): IntersectionItem {
  if (item && !item.endDate && item.presentlyEmployed) {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const monthStr = month < 10 ? "0" + month : month;
    return {
      ...item,
      endDate: new Date([year, monthStr, 15].join("-")).getTime()
    };
  }

  return item;
}

function injectValueIntoInput(
  inputs: InputsProps,
  item: IntersectionItem,
  section: Section,
  sectionTitle: string
): void {
  if ((item && item[inputs.id]) || (section && isNotASection(sectionTitle))) {
    inputs.properties.value = item[inputs.id];
  } else if (section && isSingleItemDisplayedType(sectionTitle)) {
    inputs.properties.value = section["description"];
  } else if (section && isSingleConcatItemDisplayedType(sectionTitle)) {
    inputs.properties.value = section.items;
  }
}

export const getSection = (sectionId: string, sections: Section[]): Section => {
  let section = null;
  if (Array.isArray(sections)) {
    section = sections.find(sectionCur => sectionCur.id === sectionId);
  }
  if (!section && Array.isArray(sections)) {
    section = sections.find(sectionCur => sectionCur.title === sectionId);
  }
  return section;
};

export const getSectionName = (
  sectionId: string,
  sections: Section[]
): string => {
  const section = getSection(sectionId, sections);
  let sectionName = null;
  if (section && section.name) {
    sectionName = section.name;
  } else {
    sectionName = capitalize(getTypeDisplayName(sectionId));
  }
  return sectionName;
};

export const getSectionItems = (
  type: string,
  sections: Section[]
): IntersectionItem[] => {
  const section = getSection(type, sections);
  return section && section.items ? section.items : [];
};

export const getSectionItemsField = (
  type: string,
  sections: Section[],
  field: string
): any[] => {
  const sectionItems = getSectionItems(type, sections);
  const itemList = sectionItems.map(item => item[field]);

  return itemList;
};

export const getSectionItem = (
  type: string,
  sections: Section[],
  itemId: string
): IntersectionItem => {
  const section = getSection(type, sections);
  const items = section ? section.items : [];
  const item = items.find(itm => itm.id === itemId);
  return item;
};

export const getSectionItemField = (
  type: string,
  sections: Section[],
  field: string,
  itemId: string
): any => {
  const sectionItem = getSectionItem(type, sections, itemId);

  return sectionItem ? sectionItem[field] : undefined;
};

export const isSingleConcatItemDisplayedType = (type: string): boolean => {
  return getSectionConfig(type)["displayAsSingleConcatItem"] || false;
};

export const isSingleItemDisplayedType = (type: string): boolean => {
  return getSectionConfig(type)["displayAsSingleItem"] || false;
};

export const isNotASection = (type: string): boolean => {
  return getSectionConfig(type)["notSection"] || false;
};

export const getTypeDisplayName = (
  sectionTitle: string,
  singularOrPlural?: "singular" | "plural"
): string => {
  const displayType =
    getSectionConfig(sectionTitle)["displayName"] || sectionTitle;
  if (
    singularOrPlural === "plural" &&
    getSectionConfig(sectionTitle)["noPlural"] !== true
  ) {
    return `${displayType}s`;
  }
  return displayType;
};

export const setResumePlaceholder = (
  isVerified: boolean,
  resume: Resume
): Resume => {
  let showContactDetailsPlaceholder = true;

  const setPlaceHolder = (
    obj: ContactDetails,
    key: string,
    defaultVal: any,
    isSkip: boolean
  ) => {
    if (isObjectLogicEmpty(obj[key])) {
      if (!isSkip) {
        obj[key] = defaultVal;
      }
    } else {
      if (key !== "lastName" && key !== "firstName") {
        showContactDetailsPlaceholder = false;
      }
    }
  };

  const displayedResume = cloneDeep(resume);
  const {
    contactDetails = {},
    sections = [],
    sectionsOrder = []
  } = displayedResume;

  const resumePlaceholders = returnResumePlaceholders(isVerified, resume?.id);

  const specialFields = ["city", "state"];
  for (const [key, val] of Object.entries(resumePlaceholders.contactDetails)) {
    setPlaceHolder(contactDetails, key, val, specialFields.includes(key));
  }

  if (specialFields.every(field => isObjectLogicEmpty(contactDetails[field]))) {
    specialFields.forEach(field => {
      contactDetails[field] = resumePlaceholders.contactDetails[field];
    });
  }

  contactDetails.placeholder = showContactDetailsPlaceholder;

  for (const [index, order] of sectionsOrder.entries()) {
    //no section in backend but in orders
    if (isObjectLogicEmpty(sections.filter(section => section.id === order))) {
      sections.push({
        ...resumePlaceholders[order],
        order: index,
        placeholder: true
      });
    } else {
      // empty section in backend
      const section = sections.filter(section => section.id === order);
      if ([EMPLOYMENT_SECTION, EDUCATION_SECTION].includes(order)) {
        if (section[0]?.items?.length === 0) {
          section[0].items = resumePlaceholders[order]?.items;
          section[0].placeholder = true;
        }
      } else if (
        [
          SKILLS_SECTION,
          LANGUAGES_SECTION,
          CERTIFICATES_SECTION,
          AWARDS_SECTION
        ].includes(order)
      ) {
        if (section[0]?.items?.length === 0) {
          section[0].items = resumePlaceholders[order]?.items;
          section[0].placeholder = true;
        }
      } else if (order.indexOf("-") !== -1) {
        // Custom section
        if (!section[0].description) {
          section[0].description =
            "Click the 'Edit' button to add content to this section.";
          section[0].placeholder = true;
        }
      } else {
        if (section[0]?.description === "") {
          section[0].description = resumePlaceholders[order]?.description;
          section[0].placeholder = true;
        }
      }
    }
  }
  return displayedResume;
};

export const getOriginalInputValues = (
  sectionId: string,
  resume: Resume,
  itemId: string
): ContactDetails | IntersectionItem[] | Section => {
  let originalInputValues: ContactDetails | IntersectionItem[] | Section;
  if (sectionId === "contactDetails") {
    originalInputValues = resume.contactDetails;
  } else if (itemId) {
    originalInputValues = getSectionItem(sectionId, resume.sections, itemId);
  } else {
    originalInputValues = getSection(sectionId, resume.sections);
    if (
      originalInputValues &&
      originalInputValues.items &&
      Array.isArray(originalInputValues.items) &&
      originalInputValues.items.length === 0
    ) {
      originalInputValues = [];
    }
  }
  return originalInputValues;
};

export const isSectionUpdated = (
  originalInputValues: ContactDetails | IntersectionItem[] | Section = {},
  inputValues: InputValues
): boolean => {
  let isUpdated = false;
  const convertDescription = (description: string): string => {
    return description
      .replace(/&nbsp;/g, " ")
      .replace(/: /g, ":")
      .replace(/;">/g, '">')
      .replace(/<br>/g, "<br />");
  };
  const isEqual = (valA, valB) => {
    if (!valA && !valB) return true;
    return valA === valB;
  };

  for (const [key, val] of Object.entries(inputValues)) {
    if (key === "location") {
      continue;
    }
    if (
      key === "description" &&
      val === "<p></p>\n" &&
      !originalInputValues[key]
    )
      continue;
    if (
      key === "description" &&
      val === null &&
      isEqual(val, originalInputValues[key])
    )
      continue;
    if (
      key === "endDate" &&
      originalInputValues["presentlyEmployed"] &&
      inputValues["presentlyEmployed"]
    ) {
      continue;
    }
    if (
      (key !== "description" && !isEqual(val, originalInputValues[key])) ||
      (key === "description" &&
        typeof val === "string" &&
        !isEqual(convertDescription(val), originalInputValues[key])) ||
      (key === "description" &&
        typeof val === "object" &&
        "items" in originalInputValues &&
        !deepEqual(val, originalInputValues.items))
    ) {
      isUpdated = true;
      break;
    }
  }
  return isUpdated;
};

export const onFilterChip = (
  suggestions: string[],
  input: ChipInputItem[]
): {
  value: string;
  isGray: boolean;
}[] => {
  const filterSuggestions = suggestions.map(item => ({
    value: item,
    isGray: false
  }));
  if (input) {
    const inputContainsSuggestion = (
      inputs: ChipInputItem[],
      suggestion: string
    ) => {
      for (const input of inputs) {
        if ((input.name || "").toLowerCase() === suggestion.toLowerCase())
          return true;
      }
      return false;
    };
    filterSuggestions.forEach(item => {
      if (inputContainsSuggestion(input, item.value)) {
        item.isGray = true;
      }
    });
  }
  return filterSuggestions;
};

export const getSectionSortFlow = (
  resume: Resume
): {
  sectionId: string;
  itemId: string;
  tips: string;
}[] => {
  const sortFlow = [
    { sectionId: "contactDetails", itemId: null, tips: "profile" }
  ];

  resume.sectionsOrder.forEach(sectionId => {
    const sectionName = getSectionName(sectionId, resume.sections);
    if (SECTIONS_HAVE_ITEMS.includes(sectionId)) {
      const items = getSectionItems(sectionId, resume.sections);
      const itemsLength = items.length;
      if (itemsLength === 1) {
        items.forEach(item => {
          sortFlow.push({
            sectionId,
            itemId: item.id,
            tips: sectionName
          });
        });
      } else if (itemsLength >= 2) {
        items.forEach((item, index) => {
          sortFlow.push({
            sectionId,
            itemId: item.id,
            tips: sectionName + (index + 1)
          });
        });
      } else {
        sortFlow.push({ sectionId, itemId: null, tips: sectionName });
      }
    } else {
      sortFlow.push({ sectionId, itemId: null, tips: sectionName });
    }
  });

  return sortFlow;
};

export const checkIfResumeHasEditContent = (resume: Resume): boolean => {
  const sectionHasEditContent = resume?.sections.some(section =>
    Array.isArray(section?.items)
      ? section?.items.length > 0
      : !!section?.description
  );

  return !isContactEmpty(resume) || sectionHasEditContent;
};

export const isContactEmpty = (resume: Resume): boolean => {
  const keys = Object.keys(resume.contactDetails);
  let isEmpty = true;
  for (const key of keys) {
    if (!["__typename"].includes(key) && resume.contactDetails[key]) {
      isEmpty = false;
      break;
    }
  }
  return isEmpty;
};
