import React, { FC, ReactElement, useEffect, useState } from "react";

import { isInGroupRbRescomMaintenance } from "~lib/abTestHelper";

type Props = {
  children: ReactElement;
};

const Maintenance: FC<Props> = props => {
  const [isGroupRbRescomMaintenance, setIsGroupRbRescomMaintenance] = useState<
    boolean
  >(false);

  useEffect(() => {
    isInGroupRbRescomMaintenance().then(res => {
      setIsGroupRbRescomMaintenance(res);
    });
  }, []);

  if (isGroupRbRescomMaintenance) {
    window.location.href = "https://d3kh6busyaqkf0.cloudfront.net/";
  }
  return <>{props.children}</>;
};

export default Maintenance;
