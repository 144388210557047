export const CONTACT_SUPPORT_URL = "//support.resume.com";
export const HELP_CENTER_URL = "https://support.resume.com/hc/en-us";
export const CONTACT_US_URL =
  "https://support.resume.com/hc/en-us/articles/360059658411";

export const PRIVACY_CENTER_URL = "https://hrtechprivacy.com";
export const PRIVACY_POLICY_URL =
  "https://hrtechprivacy.com/brands/indeed#privacypolicy";

// Privacy page
export const NEWEST_PRIVACY_POLICY_URL =
  "https://hrtechprivacy.com/brands/resume#privacypolicy";
export const NEWEST_COOKIE_POLICY_URL =
  "https://hrtechprivacy.com/brands/resume#cookies";

// Share resume to Indeed
export const PUBLIC_RESUME_URL = "https://hrtechprivacy.com/brands/indeed#s4-4";
export const RESUME_VISIBILITY_URL =
  "https://support.indeed.com/hc/en-us/articles/204524164-How-do-I-change-visibility-settings-for-my-Indeed-Resume-";

// Account request data
export const REQUEST_DATA_URL =
  "https://requests.hrtechprivacy.com/create?entity=RESUMEDOTCOM&hl=en_US";

export const INDEED_URL = "https://www.indeed.com/";
export const INDEED_PROFILE_URL = "https://my.indeed.com/resume";
export const TERMS_AND_LEGAL_URL = "https://www.indeed.com/legal#tos";
export const PRIVACY_CHOICES_URL = "https://www.indeed.com/legal/ccpa-dns";
export const REQUEST_RESUME_REVIEW_URL =
  "https://www.indeed.com/career-services/resume-help?collectorID=ResumeCom";
export const RESUME_AUDIT_URL =
  "https://www.indeed.com/career-services/resume-help/instant-report?collectorID=ResumeCom";
export const INDEED_TERMS_OF_SERVICE =
  "https://www.indeed.com/legal?hl=en&redirect=true";

// Download APP
export const APPLE_STORE_URL =
  "https://apps.apple.com/us/app/resume-builder-by-resume-com/id1527659872";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.resume.builder.resumecom&hl=en_US";

export const WCAG_URL = "https://www.w3.org/WAI/standards-guidelines/wcag/";

export const LEARN_MORE_URL = "/about/indeed-partnership";

export const REBRANDING_LEARN_MORE = "/rebranding-to-indeed";
