import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  ReactNode
} from "react";

import { ApolloClient } from "@apollo/client";
import { navigate } from "gatsby";

import { client } from "~apollo/graphql-gateway/client";
import {
  createGuestUser,
  getUser
} from "~apollo/graphql-gateway/userMutationsActions";
import { isInGroupRbResumeLimitationModal1 } from "~lib/abTestHelper";
import { logout as logoutAction, verifyMagicToken } from "~lib/authentication";
import { ResponseObj } from "~lib/commonType/apollo";
import { AuthState, AuthProps, User, UserType } from "~lib/commonType/auth";
import {
  GUEST_USER_RESUME,
  NEED_CLEAR_WHEN_LOGOUT,
  SHOW_RESUME_LIMITATION_MODAL
} from "~lib/keyNamesOfLocalStorage";
import {
  hasLocalStorage,
  getLocalUserObject,
  setLocalUserObject,
  removeLocalStorageUser,
  getLocalStorageIsUser,
  setLocalStorageIsUser,
  removeLocalStorageIsUser,
  removeLocalStorageNamePlaceholder
} from "~lib/localStorage";
import { checkIfResumeHasEditContent } from "~lib/resumeFunctions";

export const USER_TYPE = {
  ANONYMOUS: "anonymous",
  GUEST: "guest",
  OLD: "old",
  NEW: "new"
};

type AuthProviderType = {
  children?: ReactNode;
};

export const AuthContext = createContext<AuthProps>({
  logout: async () => {
    return Promise.resolve();
  },
  clearAllStorage: () => {
    return null;
  },
  isVerified: false,
  isUser: false,
  isOldUser: false,
  user: null,
  userType: USER_TYPE.ANONYMOUS,
  setUser: () => {
    return null;
  },
  handleTokenValidation: async () => ({ success: false, error: null }),
  createGuest: async () => ({ success: false }),
  getUserData: async () => ({ success: false, error: null, data: { id: "" } })
});

export const AuthProvider: FC<AuthProviderType> = ({ children }) => {
  const [auth, setAuth] = useState<AuthState>({
    user: null,
    isVerified: false,
    isUser: false,
    isOldUser: false,
    userType: USER_TYPE.ANONYMOUS
  });

  const [isMoveGuestResumeError, setIsMoveGuestResumeError] = useState(false);

  useEffect(() => {
    initializeUser();
  }, []);

  const defaultContext = (): AuthProps => {
    return {
      logout,
      setUser: setSession,
      handleTokenValidation,
      createGuest,
      getUserData,
      clearAllStorage,
      ...auth
    };
  };

  const getUserData = async (userId: string): Promise<ResponseObj> => {
    const userResponse = await getUser(userId);
    setSession(userResponse.data);
    return userResponse;
  };

  const handleTokenValidation = async (
    accessToken: string
  ): Promise<ResponseObj> => {
    const isGroupRbResumeLimitationModal1 = await isInGroupRbResumeLimitationModal1();
    const response = await verifyMagicToken(
      accessToken,
      isGroupRbResumeLimitationModal1
    );
    if (response.success) {
      setIsMoveGuestResumeError(!!response.moveGuestResumesResult);
      if (
        !!response.moveGuestResumesResult &&
        isGroupRbResumeLimitationModal1
      ) {
        const guestUserResume = JSON.parse(
          localStorage.getItem(GUEST_USER_RESUME)
        );
        if (checkIfResumeHasEditContent(guestUserResume)) {
          localStorage.setItem(SHOW_RESUME_LIMITATION_MODAL, "true");
        }
      }
      setLocalStorageIsUser();
      const resp = await getUserData(response.user.id);
      return resp;
    }
    return { success: false, error: null };
  };

  const createGuest = async (): Promise<
    | {
        success: boolean;
        user: any;
      }
    | {
        success: boolean;
        user?: undefined;
      }
  > => {
    const response = await createGuestUser();

    if (response.success === true && typeof response.user === "object") {
      const result = await verifyMagicToken(response.magicToken);
      if (result.success) {
        setSession(response.user);
        setLocalStorageIsUser();
      }
      return result;
    }
    return { success: false };
  };

  const setUser = (user?: User | Record<string, never>): void => {
    if (user) {
      setAuth(preState => ({
        ...preState,
        user,
        isUser: isUser(user),
        isVerified: isVerified(user),
        isOldUser: isOldUser(user),
        userType: userType(user)
      }));
    } else {
      setAuth({
        user: null,
        isVerified: false,
        isUser: false,
        isOldUser: false,
        userType: USER_TYPE.ANONYMOUS
      });
    }
  };

  const setSession = (user?: User | Record<string, never>): void => {
    setUser(user);
    if (user) {
      setLocalUserObject(user);
    } else {
      removeLocalStorageUser();
      removeLocalStorageIsUser();
      removeLocalStorageNamePlaceholder();
    }
  };

  const isUser = (usr?: User | Record<string, never>): boolean => {
    const user = usr || auth.user;
    return !!(user && user.id);
  };

  const isVerified = (usr?: User | Record<string, never>): boolean => {
    const user = usr || auth.user;
    return !!(user && user.id && user.accountEmail);
  };

  const isOldUser = (usr?: User | Record<string, never>): boolean => {
    const user = usr || auth.user;
    return user && user.id && user.id.length <= 17;
  };

  const userType = (usr?: User | Record<string, never>): UserType => {
    const user = usr || auth.user;
    let userType: UserType = USER_TYPE.ANONYMOUS;
    if (isUser(user)) {
      if (!isVerified(user)) {
        console.log("isVerified", user);
        userType = USER_TYPE.GUEST;
      } else if (isOldUser(user)) {
        console.log("isOldUser", user);
        userType = USER_TYPE.OLD;
      } else {
        console.log("else", user);
        userType = USER_TYPE.NEW;
      }
    }
    return userType;
  };

  const logout = async (): Promise<void> => {
    await logoutAction();
    clearAllStorage();
  };

  const clearAllStorage = (): void => {
    setSession(null);
    removeLocalStorage();
    (client as ApolloClient<any>).resetStore();
  };

  const initializeUser = (): void => {
    const isUser = getLocalStorageIsUser();
    const user = getLocalUserObject();
    const isIndexPage = window.location.pathname === "/";
    if (hasLocalStorage() && !auth.user) {
      setUser(user);
      if (isUser && Object.keys(user).length === 0) {
        if (!isIndexPage) {
          navigate("/", { state: { forceLogout: true } });
        } else {
          removeLocalStorageIsUser();
        }
      }
    }
  };

  const removeLocalStorage = (): void => {
    NEED_CLEAR_WHEN_LOGOUT.forEach(keyName => {
      localStorage.removeItem(keyName);
    });
  };

  return (
    <AuthContext.Provider
      value={{ ...auth, ...defaultContext(), isMoveGuestResumeError }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthValue = (): AuthProps => useContext(AuthContext);

export const AuthConsumer = AuthContext.Consumer;
