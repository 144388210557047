import { client } from "./client";

import { resumeMutations } from "~apollo/graphql-gateway/queries/resumeQueries";
import {
  isObjectLogicEmpty,
  removeNullValueFieldsFromObject
} from "~lib/objectUtils";
import { unitChanger } from "~lib/unitChanger";

const { logInteraction, logPageView, logPageViewRum } = resumeMutations;

interface ParameterObj {
  [key: string]: any;
}

export const postLogInteraction = async (
  logData: ParameterObj
): Promise<any> => {
  logData = removeNullValueFieldsFromObject(logData);
  if (isObjectLogicEmpty(logData)) return null;
  try {
    await client.mutate({
      mutation: logInteraction,
      variables: {
        ...logData
      }
    });
  } catch {
    console.log("logInteraction mutation error");
  }
  return null;
};

export const postLogPageView = async (
  logData: ParameterObj
): Promise<Record<any, any>> => {
  const value = transformLogPageView(logData);
  if (logData && logData.path === logData.previousPath) {
    return null;
  }
  console.log("postLogPageView", logData);
  await client.mutate({
    mutation: logPageView,
    variables: {
      ...value
    }
  });

  return null;
};

export const transformLogPageView = (logData: ParameterObj): ParameterObj => {
  const value: ParameterObj = {};
  value.url = unitChanger(logData.url, "String");
  value.path = unitChanger(logData.path, "String");
  value.rbk = unitChanger(logData.rbk, "String");
  value.userId = unitChanger(logData.userId, "String");
  value.pageVisitId = unitChanger(logData.pageVisitId, "String");
  value.method = unitChanger(logData.method, "String");
  value.host = unitChanger(logData.host, "String");
  value.protocol = unitChanger(logData.protocol, "String");
  value.agent = unitChanger(logData.agent, "String");
  value.previousPath = unitChanger(logData.previousPath, "String");
  value.browser = unitChanger(logData.browser, "String");
  value.browserVersion = unitChanger(logData.browserVersion, "String");
  value.device = unitChanger(logData.device, "String");
  value.os = unitChanger(logData.os, "String");
  value.osVersion = unitChanger(logData.osVersion, "String");
  value.engine = unitChanger(logData.engine, "String");
  value.architecture = unitChanger(logData.architecture, "String");
  value.vendor = unitChanger(logData.vendor, "String");
  value.httpVersion = unitChanger(logData.httpVersion, "String");
  value.referer = unitChanger(logData.referer, "String");
  value.querySize = unitChanger(logData.querySize, "Int");
  value.hash = unitChanger(logData.hash, "String");
  value.bodySize = unitChanger(logData.bodySize, "Int");
  value.viewPortWidth = unitChanger(logData.viewPortWidth, "Int");
  value.viewPortHeight = unitChanger(logData.viewPortHeight, "Int");
  value.isClientSideNavigation = unitChanger(
    logData.isClientSideNavigation,
    "Boolean"
  );
  value.refCanonical = unitChanger(logData.refCanonical, "String");
  value.isNoFollow = unitChanger(logData.isNoFollow, "Boolean");
  value.isNoIndex = unitChanger(logData.isNoIndex, "Boolean");
  value.responseCode = unitChanger(logData.responseCode, "Int");
  value.sessionId = unitChanger(logData.sessionId, "String");
  value.sessionStartTime = unitChanger(logData.sessionStartTime, "Int");
  value.sessionLastInteraction = unitChanger(
    logData.sessionLastInteraction,
    "Int"
  );
  value.sessionCount = unitChanger(logData.sessionCount, "Int");
  value.sessionLandingPage = unitChanger(logData.sessionLandingPage, "String");
  value.testName = unitChanger(logData.testName, "String");
  value.isOnline = unitChanger(logData.isOnline, "Boolean");
  value.appVersion = unitChanger(logData.appVersion, "String");
  value.reftype = unitChanger(logData.reftype, "String");
  value.view = unitChanger(logData.view, "String");
  value.site = unitChanger(logData.site, "String");
  value.grp = unitChanger(logData.grp, "String");
  value.bot = unitChanger(logData.bot, "Boolean");
  if (isObjectLogicEmpty(value)) return null;
  return value;
};

export const postLogPageViewRum = async (
  logData: ParameterObj
): Promise<Record<any, any>> => {
  const value = transformLogPageViewRum(logData);
  if (process.env.NODE_ENV === "development") {
    console.log("postLogPageViewRum", logData);
  }
  await client.mutate({
    mutation: logPageViewRum,
    variables: {
      ...value
    }
  });

  return null;
};

export const transformLogPageViewRum = (
  logData: ParameterObj
): ParameterObj => {
  const value: ParameterObj = {};
  value.rbk = unitChanger(logData.rbk, "String");
  value.userId = unitChanger(logData.userId, "String");
  value.sessionId = unitChanger(logData.sessionId, "String");
  value.path = unitChanger(logData.path, "String");
  value.pageVisitId = unitChanger(logData.pageVisitId, "String");
  value.connectEnd = unitChanger(logData.connectEnd, "Int");
  value.connectStart = unitChanger(logData.connectStart, "Int");
  value.decodedBodySize = unitChanger(logData.decodedBodySize, "Int");
  value.domComplete = unitChanger(logData.domComplete, "Int");
  value.domContentLoadedEventEnd = unitChanger(
    logData.domContentLoadedEventEnd,
    "Int"
  );
  value.domContentLoadedEventStart = unitChanger(
    logData.domContentLoadedEventStart,
    "Int"
  );
  value.domInteractive = unitChanger(logData.domInteractive, "Int");
  value.domainLookupEnd = unitChanger(logData.domainLookupEnd, "Int");
  value.domainLookupStart = unitChanger(logData.domainLookupStart, "Int");
  value.duration = unitChanger(logData.duration, "Int");
  value.encodedBodySize = unitChanger(logData.encodedBodySize, "Int");
  value.fetchStart = unitChanger(logData.fetchStart, "Int");
  value.initiatorType = unitChanger(logData.initiatorType, "String");
  value.loadEventStart = unitChanger(logData.loadEventStart, "Int");
  value.loadEventEnd = unitChanger(logData.loadEventEnd, "Int");
  value.requestStart = unitChanger(logData.requestStart, "Int");
  value.responseEnd = unitChanger(logData.responseEnd, "Int");
  value.responseStart = unitChanger(logData.responseStart, "Int");
  value.secureConnectionStart = unitChanger(
    logData.secureConnectionStart,
    "Int"
  );
  value.unloadEventEnd = unitChanger(logData.unloadEventEnd, "Int");
  value.unloadEventStart = unitChanger(logData.unloadEventStart, "Int");
  value.transferSize = unitChanger(logData.transferSize, "Int");
  value.firstPaint = unitChanger(logData.firstPaint, "Int");
  value.isClientSideNavigation = unitChanger(
    logData.isClientSideNavigation,
    "Boolean"
  );
  value.firstContentfulPaint = unitChanger(logData.firstContentfulPaint, "Int");
  value.navigationType = unitChanger(logData.navigationType, "String");
  value.refCanonical = unitChanger(logData.refCanonical, "String");
  value.isNoFollow = unitChanger(logData.isNoFollow, "Boolean");
  value.isNoIndex = unitChanger(logData.isNoIndex, "Boolean");
  value.dwellTime = unitChanger(logData.dwellTime, "Int");
  value.sessionStartTime = unitChanger(logData.sessionStartTime, "Int");
  value.sessionLastInteraction = unitChanger(
    logData.sessionLastInteraction,
    "Int"
  );
  value.sessionCount = unitChanger(logData.sessionCount, "Int");
  value.sessionLandingPage = unitChanger(logData.sessionLandingPage, "String");
  value.isOnline = unitChanger(logData.isOnline, "Boolean");
  value.referer = unitChanger(logData.referer, "String");
  value.agent = unitChanger(logData.agent, "String");
  value.reftype = unitChanger(logData.reftype, "String");
  value.view = unitChanger(logData.view, "String");
  value.site = unitChanger(logData.site, "String");
  value.grp = unitChanger(logData.grp, "String");
  value.bot = unitChanger(logData.bot, "Boolean");

  if (isObjectLogicEmpty(value)) return null;
  return value;
};
