import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

import { RESUME_FRAGMENT } from "~apollo/graphql-gateway/fragments/resumeFragments";

const getResume: DocumentNode = gql`
  query($id: ID) {
    Resume(filter: { id: $id }) {
      ...ResumeFragment
    }
  }

  ${RESUME_FRAGMENT}
`;

const getAllResumes: DocumentNode = gql`
  query getAll {
    Resume {
      getAll {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const getLatestResumeAndAllResumeIds: DocumentNode = gql`
  query {
    Resume {
      getLatestAndOtherIDs {
        latestResume {
          ...ResumeFragment
        }
        otherIds
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const getMultiResumes: DocumentNode = gql`
  query($ids: [ID]) {
    Resume {
      getMulti(ids: $ids) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const getJobTitle: DocumentNode = gql`
  query($query: String, $maxResults: Int) {
    Autocomplete {
      jobTitle(query: $query, maxResults: $maxResults) {
        results
      }
    }
  }
`;

const getJobTitlesWithExamples: DocumentNode = gql`
  query(
    $jobTitle: String
    $normJobCategory: String
    $maxResults: Int
    $page: Int
  ) {
    Suggestions {
      AllJobTitleWithExamples(
        jobTitle: $jobTitle
        normJobCategory: $normJobCategory
        maxResults: $maxResults
        page: $page
      ) {
        results
      }
    }
  }
`;

const getSkillName: DocumentNode = gql`
  query($query: String, $maxResults: Int) {
    Autocomplete {
      skillName(query: $query, maxResults: $maxResults) {
        results
      }
    }
  }
`;

const getCompanyName: DocumentNode = gql`
  query($query: String, $maxResults: Int) {
    Autocomplete {
      companyName(query: $query, maxResults: $maxResults) {
        results
      }
    }
  }
`;

const getLocation: DocumentNode = gql`
  query($query: String) {
    Autocomplete {
      location(query: $query) {
        results {
          formatted
          city
          latitude
          longitude
          state
          country
        }
      }
    }
  }
`;

const getSkillSuggestions: DocumentNode = gql`
  query($maxResults: Int, $jobTitles: [String], $normJobCategory: String) {
    Suggestions {
      skills(
        maxResults: $maxResults
        jobTitles: $jobTitles
        normJobCategory: $normJobCategory
      ) {
        results
      }
    }
  }
`;

const getEmploymentTextSuggestions: DocumentNode = gql`
  query(
    $maxResults: Int
    $jobTitle: String
    $normJobCategory: String
    $page: Int = 0
  ) {
    Suggestions {
      EmploymentText(
        maxResults: $maxResults
        jobTitle: $jobTitle
        normJobCategory: $normJobCategory
        page: $page
      ) {
        results
      }
    }
  }
`;

const getEducationTextSuggestions: DocumentNode = gql`
  query(
    $maxResults: Int
    $educationSchools: [String]
    $educationQualifications: [String]
  ) {
    Suggestions {
      EducationText(
        maxResults: $maxResults
        educationSchools: $educationSchools
        educationQualifications: $educationQualifications
      ) {
        results
      }
    }
  }
`;

const getSummaryTextSuggestions: DocumentNode = gql`
  query($normJobCategory: String) {
    Suggestions {
      SummaryText(normJobCategory: $normJobCategory) {
        results
      }
    }
  }
`;

const tweakify: DocumentNode = gql`
  query(
    $userId: String
    $jobTitle: String
    $jobDescription: String
    $workExperience: String
  ) {
    Suggestions {
      tweakify(
        userId: $userId
        jobTitle: $jobTitle
        jobDescription: $jobDescription
        workExperience: $workExperience
      ) {
        suggestion
      }
    }
  }
`;

const create: DocumentNode = gql`
  mutation($template: String!, $title: String) {
    Resume {
      create(template: $template, title: $title) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const update: DocumentNode = gql`
  mutation($id: ID!, $template: String, $title: String, $desiredTitle: String) {
    Resume(id: $id) {
      update(template: $template, title: $title, desiredTitle: $desiredTitle) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const sortSectionItemsQuery: DocumentNode = gql`
  mutation sortSectionItems(
    $resumeId: ID!
    $sectionType: SectionType!
    $itemIds: [String]!
  ) {
    Resume(id: $resumeId) {
      sortSectionItems(sectionType: $sectionType, itemIds: $itemIds) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const addLanguageItem: DocumentNode = gql`
  mutation addLanguageItem($resumeId: ID, $name: String, $sort: Int) {
    Resume(id: $resumeId) {
      addLanguageItem(name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateLanguageItem: DocumentNode = gql`
  mutation updateLanguageItem(
    $id: ID!
    $resumeId: ID
    $name: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      updateLanguageItem(id: $id, name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeLanguageItem: DocumentNode = gql`
  mutation removeLanguageItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeLanguageItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const addSkillItem: DocumentNode = gql`
  mutation addSkillItem(
    $resumeId: ID
    $proficiency: String
    $name: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      addSkillItem(proficiency: $proficiency, name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateSkillItem: DocumentNode = gql`
  mutation updateSkillItem(
    $id: ID!
    $resumeId: ID
    $proficiency: String
    $name: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      updateSkillItem(
        id: $id
        proficiency: $proficiency
        name: $name
        sort: $sort
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeSkillItem: DocumentNode = gql`
  mutation removeSkillItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeSkillItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;
const addEmploymentItem: DocumentNode = gql`
  mutation addEmploymentItem(
    $resumeId: ID
    $description: String
    $compensationInterval: String
    $compensationCurrency: String
    $compensationAmount: String
    $presentlyEmployed: Boolean
    $startDate: Date
    $endDate: Date
    $state: String
    $country: String
    $city: String
    $company: String
    $title: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      addEmploymentItem(
        description: $description
        compensationInterval: $compensationInterval
        compensationCurrency: $compensationCurrency
        compensationAmount: $compensationAmount
        presentlyEmployed: $presentlyEmployed
        startDate: $startDate
        endDate: $endDate
        state: $state
        country: $country
        city: $city
        company: $company
        title: $title
        sort: $sort
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateEmploymentItem: DocumentNode = gql`
  mutation updateEmploymentItem(
    $id: ID!
    $resumeId: ID
    $description: String
    $compensationInterval: String
    $compensationCurrency: String
    $compensationAmount: String
    $presentlyEmployed: Boolean
    $startDate: Date
    $endDate: Date
    $state: String
    $country: String
    $city: String
    $company: String
    $title: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      updateEmploymentItem(
        id: $id
        description: $description
        compensationInterval: $compensationInterval
        compensationCurrency: $compensationCurrency
        compensationAmount: $compensationAmount
        presentlyEmployed: $presentlyEmployed
        startDate: $startDate
        endDate: $endDate
        state: $state
        country: $country
        city: $city
        company: $company
        title: $title
        sort: $sort
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeEmploymentItem: DocumentNode = gql`
  mutation removeEmploymentItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeEmploymentItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateEducationItem: DocumentNode = gql`
  mutation updateEducationItem(
    $id: ID!
    $resumeId: ID
    $endDate: Date
    $description: String
    $state: String
    $country: String
    $city: String
    $qualification: String
    $school: String
    $sort: Int
    $status: String
  ) {
    Resume(id: $resumeId) {
      updateEducationItem(
        id: $id
        endDate: $endDate
        description: $description
        state: $state
        country: $country
        city: $city
        qualification: $qualification
        school: $school
        sort: $sort
        status: $status
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const addEducationItem: DocumentNode = gql`
  mutation addEducationItem(
    $resumeId: ID
    $endDate: Date
    $description: String
    $state: String
    $country: String
    $city: String
    $qualification: String
    $school: String
    $sort: Int
    $status: String
  ) {
    Resume(id: $resumeId) {
      addEducationItem(
        endDate: $endDate
        description: $description
        state: $state
        country: $country
        city: $city
        qualification: $qualification
        school: $school
        sort: $sort
        status: $status
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeEducationItem: DocumentNode = gql`
  mutation removeEducationItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeEducationItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateContactDetails: DocumentNode = gql`
  mutation updateContactDetails(
    $resumeId: ID
    $email: String
    $phone: String
    $firstName: String
    $lastName: String
    $address: String
    $city: String
    $zip: String
    $country: String
    $state: String
    $jobCategory: String
  ) {
    Resume(id: $resumeId) {
      updateContactDetails(
        email: $email
        phone: $phone
        firstName: $firstName
        lastName: $lastName
        address: $address
        city: $city
        zip: $zip
        country: $country
        state: $state
        jobCategory: $jobCategory
      ) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateSummary: DocumentNode = gql`
  mutation updateSummary($resumeId: ID, $description: String) {
    Resume(id: $resumeId) {
      updateSummary(description: $description) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateCustom: DocumentNode = gql`
  mutation updateCustomSection(
    $resumeId: ID
    $sectionId: ID!
    $description: String
  ) {
    Resume(id: $resumeId) {
      updateCustomSection(id: $sectionId, description: $description) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const newCustomSection: DocumentNode = gql`
  mutation newCustomSection($resumeId: ID!, $name: String!) {
    Resume(id: $resumeId) {
      createCustomSection(name: $name) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateSectionName: DocumentNode = gql`
  mutation updateSectionName(
    $resumeId: ID!
    $sectionId: String!
    $newName: String!
  ) {
    Resume(id: $resumeId) {
      updateSectionName(sectionId: $sectionId, newName: $newName) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const updateSectionOrder: DocumentNode = gql`
  mutation sortSections(
    $resumeId: ID!
    $sectionIds: [String]!
    $allSectionIds: [String]!
  ) {
    Resume(id: $resumeId) {
      sortSections(sectionIds: $sectionIds, allSectionIds: $allSectionIds) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const logInteraction: DocumentNode = gql`
  mutation(
    $visitorHash: String
    $userId: String
    $sessionId: String
    $pageUrl: String
    $eventCategory: String
    $eventAction: String
    $eventLabel: String
    $eventValue: Float
    $eventTestName: String
    $ip: String
    $userAgent: String
    $arbitrary1: String
    $arbitrary2: String
    $arbitrary3: String
    $arbitrary4: String
    $appVersion: String
  ) {
    Log {
      logInteraction(
        visitorHash: $visitorHash
        userId: $userId
        sessionId: $sessionId
        pageUrl: $pageUrl
        eventCategory: $eventCategory
        eventAction: $eventAction
        eventLabel: $eventLabel
        eventValue: $eventValue
        eventTestName: $eventTestName
        ip: $ip
        userAgent: $userAgent
        arbitrary1: $arbitrary1
        arbitrary2: $arbitrary2
        arbitrary3: $arbitrary3
        arbitrary4: $arbitrary4
        appVersion: $appVersion
      ) {
        statusCode
        statusMessage
      }
    }
  }
`;

const LOGPAGEVIEWQUERY = `
  mutation(
    $url: String
    $path: String
    $rbk: String
    $userId: String
    $pageVisitId: String
    $requestMethod: String
    $host: String
    $protocol: String
    $agent: String
    $previousPath: String
    $browser: String
    $browserVersion: String
    $device: String
    $os: String
    $osVersion: String
    $engine: String
    $architecture: String
    $vendor: String
    $httpVersion: String
    $ip: String
    $referer: String
    $querySize: Int
    $hash: String
    $bodySize: Int
    $viewPortWidth: Int
    $viewPortHeight: Int
    $isClientSideNavigation: Boolean
    $refCanonical: String
    $isNoFollow: Boolean
    $isNoIndex: Boolean
    $responseCode: Int
    $sessionId: String
    $sessionStartTime: Float
    $sessionLastInteraction: Float
    $sessionCount: Int
    $sessionLandingPage: String
    $testName: String
    $isOnline: Boolean
    $appVersion: String
    $reftype: String
    $view: String
    $site: String
    $grp: String
    $privileged: Boolean
    $bot: Boolean
  ) {
    Log {
      logPageView(
        url: $url
        path: $path
        rbk: $rbk
        userId: $userId
        pageVisitId: $pageVisitId
        requestMethod: $requestMethod
        host: $host
        protocol: $protocol
        agent: $agent
        previousPath: $previousPath
        browser: $browser
        browserVersion: $browserVersion
        device: $device
        os: $os
        osVersion: $osVersion
        engine: $engine
        architecture: $architecture
        vendor: $vendor
        httpVersion: $httpVersion
        ip: $ip
        referer: $referer
        querySize: $querySize
        hash: $hash
        bodySize: $bodySize
        viewPortWidth: $viewPortWidth
        viewPortHeight: $viewPortHeight
        isClientSideNavigation: $isClientSideNavigation
        refCanonical: $refCanonical
        isNoFollow: $isNoFollow
        isNoIndex: $isNoIndex
        responseCode: $responseCode
        sessionId: $sessionId
        sessionStartTime: $sessionStartTime
        sessionLastInteraction: $sessionLastInteraction
        sessionCount: $sessionCount
        sessionLandingPage: $sessionLandingPage
        testName: $testName
        isOnline: $isOnline
        appVersion: $appVersion
        reftype: $reftype
        view: $view
        site: $site
        grp: $grp
        privileged: $privileged
        bot: $bot
      ) {
        statusCode
        statusMessage
      }
    }
  }
`;

const logPageView: DocumentNode = gql(LOGPAGEVIEWQUERY);

const LOGPAGEVIEWRUMQUERY = `
  mutation(
    $rbk: String
    $userId: String
    $sessionId: String
    $path: String
    $pageVisitId: String
    $connectEnd: Int
    $connectStart: Int
    $decodedBodySize: Int
    $domComplete: Int
    $domContentLoadedEventEnd: Int
    $domContentLoadedEventStart: Int
    $domInteractive: Int
    $domainLookupEnd: Int
    $domainLookupStart: Int
    $duration: Int
    $encodedBodySize: Int
    $fetchStart: Int
    $initiatorType: String
    $loadEventStart: Int
    $loadEventEnd: Int
    $requestStart: Int
    $responseEnd: Int
    $responseStart: Int
    $secureConnectionStart: Int
    $unloadEventEnd: Int
    $unloadEventStart: Int
    $transferSize: Int
    $firstPaint: Int
    $isClientSideNavigation: Boolean
    $firstContentfulPaint: Int
    $navigationType: String
    $refCanonical: String
    $isNoFollow: Boolean
    $isNoIndex: Boolean
    $dwellTime: Int
    $sessionStartTime: Float
    $sessionLastInteraction: Float
    $sessionCount: Int
    $sessionLandingPage: String
    $isOnline: Boolean
    $referer: String
    $reftype: String
    $view: String
    $site: String
    $grp: String
    $bot: Boolean
  ) {
    Log {
      logPageViewRum(
        rbk: $rbk
        userId: $userId
        sessionId: $sessionId
        path: $path
        pageVisitId: $pageVisitId
        connectEnd: $connectEnd
        connectStart: $connectStart
        decodedBodySize: $decodedBodySize
        domComplete: $domComplete
        domContentLoadedEventEnd: $domContentLoadedEventEnd
        domContentLoadedEventStart: $domContentLoadedEventStart
        domInteractive: $domInteractive
        domainLookupEnd: $domainLookupEnd
        domainLookupStart: $domainLookupStart
        duration: $duration
        encodedBodySize: $encodedBodySize
        fetchStart: $fetchStart
        initiatorType: $initiatorType
        loadEventStart: $loadEventStart
        loadEventEnd: $loadEventEnd
        requestStart: $requestStart
        responseEnd: $responseEnd
        responseStart: $responseStart
        secureConnectionStart: $secureConnectionStart
        unloadEventEnd: $unloadEventEnd
        unloadEventStart: $unloadEventStart
        transferSize: $transferSize
        firstPaint: $firstPaint
        isClientSideNavigation: $isClientSideNavigation
        firstContentfulPaint: $firstContentfulPaint
        navigationType: $navigationType
        refCanonical: $refCanonical
        isNoFollow: $isNoFollow
        isNoIndex: $isNoIndex
        dwellTime: $dwellTime
        sessionStartTime: $sessionStartTime
        sessionLastInteraction: $sessionLastInteraction
        sessionCount: $sessionCount
        sessionLandingPage: $sessionLandingPage
        isOnline: $isOnline
        referer: $referer
        reftype: $reftype
        view: $view
        site: $site
        grp: $grp
        bot: $bot
      ) {
        statusCode
        statusMessage
      }
    }
  }
`;

const logPageViewRum: DocumentNode = gql(LOGPAGEVIEWRUMQUERY);

const deleteResume: DocumentNode = gql`
  mutation deleteResume($id: ID) {
    Resume {
      remove(id: $id) {
        message
      }
    }
  }
`;

const deleteCustomSection: DocumentNode = gql`
  mutation removeCustomSection($resumeId: ID, $sectionId: ID!) {
    Resume(id: $resumeId) {
      removeCustomSection(id: $sectionId) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const sendResumeEmail: DocumentNode = gql`
  mutation sendResumeEmail($resumeId: ID, $recipientEmail: String) {
    Resume {
      sendResumeEmail(resumeId: $resumeId, recipientEmail: $recipientEmail) {
        message
      }
    }
  }
`;

const addAnalyticsTimeExported: DocumentNode = gql`
  mutation addAnalyticsTimeExported($resumeId: ID) {
    Resume(id: $resumeId) {
      addAnalyticsTimeExported {
        message
      }
    }
  }
`;

const moveGuestResume: DocumentNode = gql`
  mutation moveGuestResumesToAccount($guestUserId: String!) {
    Resume {
      moveGuestResumesToAccount(guestUserId: $guestUserId) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const restart: DocumentNode = gql`
  mutation RestartResume($resumeId: ID, $title: String, $template: String) {
    Resume {
      restart(id: $resumeId, title: $title, template: $template) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const duplicate: DocumentNode = gql`
  mutation duplicate($id: ID!, $newTitle: String) {
    Resume {
      duplicate(id: $id, newTitle: $newTitle) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const deactivateSection: DocumentNode = gql`
  mutation deactivateSection($resumeId: ID, $sectionId: String, $type: String) {
    Resume(id: $resumeId) {
      deactivateSection(sectionId: $sectionId, type: $type) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const activateSection: DocumentNode = gql`
  mutation activateSection($resumeId: ID, $sectionId: String, $type: String) {
    Resume(id: $resumeId) {
      activateSection(sectionId: $sectionId, type: $type) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const addCertificateItem: DocumentNode = gql`
  mutation addCertificateItem($resumeId: ID, $name: String, $sort: Int) {
    Resume(id: $resumeId) {
      addCertificateItem(name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateCertificateItem: DocumentNode = gql`
  mutation updateCertificateItem(
    $id: ID!
    $resumeId: ID
    $name: String
    $sort: Int
  ) {
    Resume(id: $resumeId) {
      updateCertificateItem(id: $id, name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeCertificateItem: DocumentNode = gql`
  mutation removeCertificateItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeCertificateItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const addAwardItem: DocumentNode = gql`
  mutation addAwardItem($resumeId: ID, $name: String, $sort: Int) {
    Resume(id: $resumeId) {
      addAwardItem(name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateAwardItem: DocumentNode = gql`
  mutation updateAwardItem($id: ID!, $resumeId: ID, $name: String, $sort: Int) {
    Resume(id: $resumeId) {
      updateAwardItem(id: $id, name: $name, sort: $sort) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const removeAwardItem: DocumentNode = gql`
  mutation removeAwardItem($resumeId: ID, $id: ID!) {
    Resume(id: $resumeId) {
      removeAwardItem(id: $id) {
        ...ResumeFragment
      }
    }
  }

  ${RESUME_FRAGMENT}
`;

const updateNextStepServicesStatus: DocumentNode = gql`
  mutation setNextStepServicesStatus(
    $resumeId: ID
    $option1: Boolean
    $option2: Boolean
    $option3: Boolean
  ) {
    Resume(id: $resumeId) {
      setNextStepServicesStatus(
        option1: $option1
        option2: $option2
        option3: $option3
      ) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const updateShareStatus: DocumentNode = gql`
  mutation updateShareStatus($resumeId: ID, $isShared: Boolean!) {
    Resume(id: $resumeId) {
      updateShareStatus(isShared: $isShared) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const upsertResume: DocumentNode = gql`
  mutation upsert($resumeData: String) {
    Resume {
      upsert(resumeData: $resumeData) {
        ...ResumeFragment
      }
    }
  }
  ${RESUME_FRAGMENT}
`;

const uploadResume: DocumentNode = gql`
  mutation uploadResume($file: Upload!) {
    Resume {
      uploadResume(file: $file) {
        resumeId
      }
    }
  }
`;

export const resumeQueries = {
  getResume,
  getAllResumes,
  getLatestResumeAndAllResumeIds,
  getMultiResumes,
  getJobTitle,
  getJobTitlesWithExamples,
  getSkillName,
  getCompanyName,
  getLocation,
  getSkillSuggestions,
  getEmploymentTextSuggestions,
  getEducationTextSuggestions,
  getSummaryTextSuggestions,
  tweakify
};

export const resumeMutations = {
  update,
  create,
  addEducationItem,
  updateEducationItem,
  removeEducationItem,
  addEmploymentItem,
  updateEmploymentItem,
  removeEmploymentItem,
  addSkillItem,
  updateSkillItem,
  removeSkillItem,
  addLanguageItem,
  updateLanguageItem,
  removeLanguageItem,
  updateContactDetails,
  updateSummary,
  updateSectionName,
  updateSectionOrder,
  sortSectionItemsQuery,
  logInteraction,
  logPageView,
  deleteResume,
  newCustomSection,
  updateCustom,
  deleteCustomSection,
  LOGPAGEVIEWRUMQUERY,
  LOGPAGEVIEWQUERY,
  logPageViewRum,
  sendResumeEmail,
  moveGuestResume,
  restart,
  duplicate,
  deactivateSection,
  activateSection,
  addCertificateItem,
  updateCertificateItem,
  removeCertificateItem,
  addAwardItem,
  updateAwardItem,
  removeAwardItem,
  addAnalyticsTimeExported,
  updateNextStepServicesStatus,
  updateShareStatus,
  upsertResume,
  uploadResume
};
