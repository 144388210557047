import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const COVER_LETTER_FRAGMENT: DocumentNode = gql`
  fragment CoverLetterFragment on CoverLetter {
    id
    title
    template
    createdDate
    updatedDate
    body
    footer
    contactDetails {
      firstName
      lastName
      email
      phone
      jobCategory
    }
  }
`;
