import { client } from "./client";

import {
  coverLetterMutations,
  coverLetterQueries
} from "~apollo/graphql-gateway/queries/coverLetterQueries";
import { CoverLetter } from "~lib/commonType/coverLetter";

const {
  updateContactDetails,
  update,
  updateFooter,
  create,
  deleteCoverLetter,
  sendCoverLetterEmail,
  restart,
  moveGuestCoverLetter,
  duplicate,
  upsertCoverLetter
} = coverLetterMutations;
const { getAllCoverLetters } = coverLetterQueries;

interface InputParams {
  description?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
}

export const updateContactDetailsInfo = (coverLetterId: string) => async (
  input: InputParams
): Promise<Record<any, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    await client.mutate({
      mutation: updateContactDetails,
      variables: {
        coverLetterId,
        ...input
      }
    });
  } catch (e) {
    throw e;
  }
  return null;
};

export const updateCoverLetter = async (
  coverLetterId: string,
  body: string,
  template?: string,
  title?: string
): Promise<Record<any, any>> => {
  let response = { success: false };
  try {
    const res = await client.mutate({
      mutation: update,
      variables: {
        id: coverLetterId,
        body,
        template,
        title
      }
    });
    if (res.data?.CoverLetter?.update) {
      response = { ...res, success: true };
    }
    return response;
  } catch (e) {
    console.log(e);
    return response;
  }
};

export const updateFooterInfo = async (
  coverLetterId: string,
  footer: string
): Promise<Record<any, any>> => {
  try {
    const { data } = await client.mutate({
      mutation: updateFooter,
      variables: {
        id: coverLetterId,
        footer
      }
    });
    // console.log("cover letter data", data);
    return data;
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const createCoverLetter = async (
  templateId: string
): Promise<Record<any, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    const { data } = await client.mutate({
      mutation: create,
      variables: {
        template: templateId
      },
      refetchQueries: [
        {
          query: getAllCoverLetters
        }
      ]
    });
    if (data) {
      return data.CoverLetter.create;
    }
  } catch (e) {
    throw e;
  }
  return null;
};

export const removeCoverLetter = async (
  coverLetterId: string
): Promise<Record<any, any>> => {
  if (coverLetterId) {
    try {
      return client.mutate({
        mutation: deleteCoverLetter,
        variables: {
          id: coverLetterId
        },
        refetchQueries: [
          {
            query: getAllCoverLetters
          }
        ]
      });
    } catch (e) {
      console.log(e);
      client.query({
        query: getAllCoverLetters
      });
    }
  }
  return { data: null };
};

export const sendCoverLetter = async (
  coverLetterId: string,
  recipientEmail: string
): Promise<Record<any, any>> => {
  try {
    return await client.mutate({
      mutation: sendCoverLetterEmail,
      variables: {
        coverLetterId,
        recipientEmail
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const restartCoverLetter = async (
  coverLetterId: string,
  title: string,
  templateId: string
): Promise<Record<any, any>> => {
  if (coverLetterId) {
    // eslint-disable-next-line no-useless-catch
    try {
      const { data } = await client.mutate({
        mutation: restart,
        variables: {
          coverLetterId,
          title,
          template: templateId
        }
      });
      return data?.CoverLetter?.restart?.id;
    } catch (e) {
      throw e;
    }
  }
  return null;
};

export const moveGuestCoverLettersToAccount = async (
  guestUserId: string
): Promise<Record<any, any>> => {
  // eslint-disable-next-line no-useless-catch
  try {
    return await client.mutate({
      mutation: moveGuestCoverLetter,
      variables: {
        guestUserId
      },
      refetchQueries: [
        {
          query: getAllCoverLetters
        }
      ]
    });
  } catch (e) {
    throw e;
  }
};

export const duplicateCoverLetter = async (
  id: string,
  title: string
): Promise<{
  success: boolean;
  data: { id: string };
  error: { graphQLErrors: Array<Record<string, any>> };
}> => {
  const response = { success: false, error: null, data: { id: null } };
  if (id) {
    try {
      const { data } = await client.mutate({
        mutation: duplicate,
        variables: {
          id,
          title
        },
        refetchQueries: [
          {
            query: getAllCoverLetters
          }
        ]
      });
      if (data && data.CoverLetter && data.CoverLetter.duplicate) {
        response.success = true;
        response.data.id = data.CoverLetter.duplicate.id;
        return response;
      }
      return response;
    } catch (e) {
      response.error = e;
      return response;
    }
  }
};

export const createCoverLetterFromSample = async (
  coverLetterData: string
): Promise<CoverLetter> => {
  try {
    const { data } = await client.mutate({
      mutation: upsertCoverLetter,
      variables: {
        coverLetterData
      },
      refetchQueries: [
        {
          query: getAllCoverLetters
        }
      ]
    });

    if (data) {
      return data?.CoverLetter?.upsert;
    }
  } catch (e) {
    return e;
  }
  return null;
};
