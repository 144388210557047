import { User } from "~lib/commonType/auth";
import {
  COVER_LETTER_IMAGES,
  IS_USER,
  RANDOM_FIRST_NAMES,
  RANDOM_LAST_NAMES,
  RESUME_IMAGES,
  RESUME_USER,
  TEMPLATE
} from "~lib/keyNamesOfLocalStorage";
import { CLEAR_UNUSED_STORAGE_ITEMS } from "~lib/keyNamesOfSessionStorage";

export const hasLocalStorage = (): boolean => {
  return typeof Storage !== "undefined" && typeof localStorage === "object";
};

export const getLocalUserObject = (): User | Record<string, never> => {
  if (hasLocalStorage()) {
    const tempUserObj = window.localStorage.getItem(RESUME_USER);
    if (tempUserObj !== "undefined" && tempUserObj) {
      return JSON.parse(tempUserObj);
    }
  }
  return {};
};

export const setLocalUserObject = (
  user: User | Record<string, never>
): void => {
  if (hasLocalStorage() && user) {
    localStorage.setItem(RESUME_USER, JSON.stringify(user));
  }
};

export const removeLocalStorageUser = (): void => {
  if (hasLocalStorage()) {
    localStorage.removeItem(RESUME_USER);
  }
};

export const getLocalStorageIsUser = (): string => {
  let isUser = "false";
  if (hasLocalStorage()) {
    isUser = localStorage.getItem(IS_USER);
  }
  return isUser;
};

export const setLocalStorageIsUser = (): void => {
  if (hasLocalStorage()) {
    localStorage.setItem(IS_USER, "true");
  }
};

export const removeLocalStorageIsUser = (): void => {
  if (hasLocalStorage()) {
    localStorage.removeItem(IS_USER);
  }
};

export const removeLocalStorageResumeOrCoverLetter = (
  id: string,
  isResume: boolean
): void => {
  const items = isResume
    ? localStorage.getItem(RESUME_IMAGES)
    : localStorage.getItem(COVER_LETTER_IMAGES);
  const parsedItems = JSON.parse(items);

  if (parsedItems && Object.prototype.hasOwnProperty.call(parsedItems, id)) {
    delete parsedItems[id];
    isResume
      ? localStorage.setItem(RESUME_IMAGES, JSON.stringify(parsedItems))
      : localStorage.setItem(COVER_LETTER_IMAGES, JSON.stringify(parsedItems));
  }
};

export const getLocalStorageTemplate = (): string => {
  let template = "rec1";
  if (hasLocalStorage()) {
    template = localStorage.getItem(TEMPLATE);
  }
  return template;
};

export const setLocalStorageTemplate = (templateCode: string): void => {
  if (hasLocalStorage()) {
    localStorage.setItem(TEMPLATE, templateCode);
  }
};

export const removeLocalStorageTemplate = (): void => {
  if (hasLocalStorage()) {
    localStorage.removeItem(TEMPLATE);
  }
};

export const getLocalStorageFirstNamePlaceholder = (): string => {
  let firstNamePlaceholder: string;
  if (hasLocalStorage()) {
    firstNamePlaceholder = localStorage.getItem(RANDOM_FIRST_NAMES);
  }
  return firstNamePlaceholder;
};

export const setLocalStorageFirstNamePlaceholder = (
  randomFirstNamePlaceholder: string
): void => {
  if (hasLocalStorage() && !getLocalStorageFirstNamePlaceholder()) {
    localStorage.setItem(RANDOM_FIRST_NAMES, randomFirstNamePlaceholder);
  }
};

export const getLocalStorageLastNamePlaceholder = (): string => {
  let lastNamePlaceholder: string;
  if (hasLocalStorage()) {
    lastNamePlaceholder = localStorage.getItem(RANDOM_LAST_NAMES);
  }
  return lastNamePlaceholder;
};

export const setLocalStorageLastNamePlaceholder = (
  randomLastNamePlaceholder: string
): void => {
  if (hasLocalStorage() && !getLocalStorageLastNamePlaceholder()) {
    localStorage.setItem(RANDOM_LAST_NAMES, randomLastNamePlaceholder);
  }
};

export const removeLocalStorageNamePlaceholder = (): void => {
  if (hasLocalStorage()) {
    localStorage.removeItem(RANDOM_FIRST_NAMES);
    localStorage.removeItem(RANDOM_LAST_NAMES);
  }
};

// Clear Unused LocalStorage Items
export const clearUnusedLocalStorageItems = (): void => {
  // Only trigger once
  if (sessionStorage.getItem(CLEAR_UNUSED_STORAGE_ITEMS)) {
    return;
  }
  sessionStorage.setItem(CLEAR_UNUSED_STORAGE_ITEMS, "true");
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    const regex = /^\s*([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})\s*$/;
    const match = regex.exec(key);
    if (!match) {
      continue;
    }
    const value = localStorage.getItem(key);
    if (localStorage.getItem(key) !== null) {
      const valueObj = JSON.parse(value);
      if (valueObj && valueObj["resumeOrCoverLetterImg"]) {
        localStorage.removeItem(key);
      }
    }
  }
};
