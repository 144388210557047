import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const USER_FRAGMENT: DocumentNode = gql`
  fragment UserFragment on User {
    id
    accountEmail
    profileEmail
    firstName
    lastName
    username
    indeedShareStatus
    oAuthProviders
    connectedWithIndeed
  }
`;
