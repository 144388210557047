import UAParser from "ua-parser-js";

export const DeviceTypes = {
  Mobile: "mobile",
  Tablet: "tablet",
  Browser: undefined
};

export const OsTypes = {
  IOS: "iOS",
  Android: "Android"
};

export const BrowserTypes = {
  Chrome: "Chrome",
  Safari: "Safari",
  Firefox: "Firefox"
};

const getNavigatorInstance = (): Navigator => {
  if (typeof window !== "undefined") {
    if (window.navigator || navigator) {
      return window.navigator || navigator;
    }
  }
  return undefined;
};

type DeviceType = {
  model: string;
  type: string | undefined;
  vendor: string;
};

type OsType = {
  name: string;
  version: string;
};

type BrowserType = {
  major: string;
  name: string;
  version: string;
};

const parserDevice = (): {
  device: DeviceType;
  os: OsType;
  browser: BrowserType;
} => {
  const UserAgentInstance = new UAParser(getNavigatorInstance()?.userAgent);

  return {
    device: UserAgentInstance.getDevice(),
    os: UserAgentInstance.getOS(),
    browser: UserAgentInstance.getBrowser()
  };
};

const UAHelper = parserDevice();

// browser types
const isChromeBrowser = ({ name }): boolean => name === BrowserTypes.Chrome;
const isSafariBrowser = ({ name }): boolean =>
  name?.includes(BrowserTypes.Safari);
const isFirefoxBrowser = ({ name }): boolean => name === BrowserTypes.Firefox;

// device types
const isMobileType = ({ type }): boolean => type === DeviceTypes.Mobile;
const isTabletType = ({ type }): boolean => type === DeviceTypes.Tablet;
const isMobileAndTabletType = ({ type }): boolean =>
  type === DeviceTypes.Mobile || type === DeviceTypes.Tablet;

// os types
export const isAndroidType = ({ name }): boolean => name === OsTypes.Android;
export const isIOSType = ({ name }): boolean => name === OsTypes.IOS;

const isIOS13Check = (type: string): boolean => {
  const nav = getNavigatorInstance();
  return (
    nav &&
    nav.platform &&
    (nav.platform.indexOf(type) !== -1 ||
      (nav.platform === "MacIntel" &&
        nav.maxTouchPoints > 1 &&
        !window["MSStream"]))
  );
};
const getIPad13 = () => isIOS13Check("iPad");

export const isMobileAndTable =
  isMobileAndTabletType(UAHelper.device) || getIPad13();
export const isMobileOnly = isMobileType(UAHelper.device);
export const isTablet = isTabletType(UAHelper.device) || getIPad13();
export const isIOS = isIOSType(UAHelper.os) || getIPad13();
export const isAndroid = isAndroidType(UAHelper.os);

export const isChrome = isChromeBrowser(UAHelper.browser);
export const isSafari = isSafariBrowser(UAHelper.browser);
export const isFirefox = isFirefoxBrowser(UAHelper.browser);
