export function unitChanger(
  value: string | number,
  type: string
): string | number | boolean {
  switch (type) {
    case "Int": {
      if (typeof value === "number") {
        return parseInt(value?.toFixed(0));
      } else if (typeof value === "string") {
        return parseInt(value) || 0;
      }
      return 0;
    }
    case "Boolean": {
      return !!value;
    }
    case "String": {
      return value && `${value}`;
    }
    case "Float": {
      if (typeof value === "number") {
        return value;
      } else if (typeof value === "string") {
        return parseFloat(value) || 0.0;
      }
      return 0.0;
    }
    default:
      return value;
  }
}
