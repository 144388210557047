// extracted by mini-css-extract-plugin
export var autoClickIcon = "styles-module--auto-click-icon--45f2a";
export var closeBtn = "styles-module--close-btn--2abaa";
export var downloadAppBtn = "styles-module--download-app-btn--ae81a";
export var downloadAppIcon = "styles-module--download-app-icon--9c2b9";
export var downloadAppText = "styles-module--download-app-text--d4e6e";
export var fadeOut = "styles-module--fade-out--b2eb6";
export var modalOverlay = "styles-module--modal-overlay--2b0a9";
export var modalWrapper = "styles-module--modal-wrapper--9a9f7";
export var slideFade = "styles-module--slide-fade--e6061";
export var slideTl = "styles-module--slide-tl--a215f";