import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const EMPLOYMENT_FRAGMENT: DocumentNode = gql`
  fragment EmploymentFragment on Employment {
    id
    description
    compensationInterval
    compensationCurrency
    compensationAmount
    presentlyEmployed
    startDate
    endDate
    state
    country
    city
    company
    title
    sort
  }
`;

export const SKILL_FRAGMENT: DocumentNode = gql`
  fragment SkillFragment on Skill {
    id
    proficiency
    name
    sort
  }
`;

export const LANGUAGE_FRAGMENT: DocumentNode = gql`
  fragment LanguageFragment on Language {
    id
    proficiency
    name
    sort
  }
`;

export const REFERENCE_FRAGMENT: DocumentNode = gql`
  fragment ReferenceFragment on Reference {
    id
    address2
    address
    phone
    email
    company
    relationship
    name
    sort
  }
`;

export const HIGHLIGHT_FRAGMENT: DocumentNode = gql`
  fragment HighlightFragment on Highlight {
    id
    title
    description
    order
  }
`;

export const EDUCATION_FRAGMENT: DocumentNode = gql`
  fragment EducationFragment on Education {
    id
    sort
    state
    endDate
    country
    city
    status
    school
    qualification
    description
  }
`;

export const CONTACT_FRAGMENT: DocumentNode = gql`
  fragment ContactFragment on ContactDetails {
    email
    phone
    firstName
    lastName
    address
    city
    zip
    country
    state
    jobCategory
  }
`;

export const CERTIFICATE_FRAGMENT: DocumentNode = gql`
  fragment CertificateFragment on Certificate {
    id
    name
    sort
  }
`;

export const AWARD_FRAGMENT: DocumentNode = gql`
  fragment AwardFragment on Award {
    id
    name
    sort
  }
`;

export const RESUME_FRAGMENT: DocumentNode = gql`
  fragment ResumeFragment on Resume {
    id
    title
    template
    createdDate
    updatedDate
    wordCount
    desiredTitle
    contactDetails {
      ...ContactFragment
    }
    nextStepServicesStatus {
      option1
      option2
      option3
    }
    analytics {
      timeExported
    }
    share
    sections {
      id
      title
      name
      order
      description
      template
      visible
      items {
        __typename
        ...EmploymentFragment
        ...SkillFragment
        ...LanguageFragment
        ...ReferenceFragment
        ...HighlightFragment
        ...EducationFragment
        ...CertificateFragment
        ...AwardFragment
      }
    }
    sectionsOrder
    allSectionsOrder
  }
  ${EMPLOYMENT_FRAGMENT}
  ${SKILL_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${HIGHLIGHT_FRAGMENT}
  ${EDUCATION_FRAGMENT}
  ${CONTACT_FRAGMENT}
  ${CERTIFICATE_FRAGMENT}
  ${AWARD_FRAGMENT}
`;
