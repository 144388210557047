import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import { isInGroupRbRescomRebrandToIndeed1 } from "~lib/abTestHelper";

export const THEME_NAME = {
  RESCOM: "RESCOM",
  INDEED: "INDEED"
};

const THEME_STYLE = {
  rescom: {
    primaryBlue: "#440ccc"
  },
  indeed: {
    primaryBlue: "#2557a7"
  }
};

interface ThemeContextProps {
  themeColor: any;
  toggleTheme: () => void;
  mode: string;
}

type ThemeProviderType = {
  children?: ReactNode;
};

export const ThemeContext = createContext<ThemeContextProps>({
  mode: THEME_NAME.RESCOM,
  themeColor: THEME_STYLE.rescom,
  toggleTheme: () => {}
});

export const useThemeContext = (): ThemeContextProps =>
  useContext(ThemeContext);

export const ThemeProvider: FC<ThemeProviderType> = ({ children }) => {
  const [mode, setMode] = useState(THEME_NAME.RESCOM);

  useEffect(() => {
    isInGroupRbRescomRebrandToIndeed1()
      .then(res => {
        if (res) {
          setMode(THEME_NAME.INDEED);
        } else {
          setMode(THEME_NAME.RESCOM);
        }
      })
      .catch(e => console.log("error: ", e));
  }, []);

  const themeColor = useMemo(() => {
    return mode === THEME_NAME.RESCOM ? THEME_STYLE.rescom : THEME_STYLE.indeed;
  }, [mode]);

  const toggleTheme = () => {
    setMode(mode === THEME_NAME.INDEED ? THEME_NAME.INDEED : THEME_NAME.RESCOM);
  };

  return (
    <ThemeContext.Provider value={{ mode, themeColor, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
