export const theme = process.env.APPLICATION_NAME || "RESCOM";
export const THEME_NAME = {
  RESCOM: "RESCOM",
  COVER_LETTER: "COVER_LETTER"
};

export const getCurrentPageThemeColor = (
  isCoverletter = null,
  isRescomTheme = true
): string => {
  if (!isRescomTheme) {
    return "";
  }
  if (isCoverletter !== null) {
    return isCoverletter ? THEME_NAME.COVER_LETTER : THEME_NAME.RESCOM;
  }
  if (typeof window !== "undefined") {
    let currentPageColor = theme;
    if (window.location.pathname.includes("/coverletter/")) {
      currentPageColor = THEME_NAME.COVER_LETTER;
    }
    return currentPageColor;
  }
  return theme;
};
