import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useState
} from "react";

import { navigate } from "gatsby";

import PageDotsLoading from "~components/loading/pageDotsLoading";
import { AuthContext } from "~context/AuthContext";
import {
  FIRST_VISIT_STATE,
  FORCE_LOGOUT,
  RESTORE_PATH
} from "~lib/keyNamesOfLocalStorage";
import { NEED_REDIRECT_TO_RESUME_BUILDER } from "~lib/keyNamesOfSessionStorage";
import { getLocalUserObject, hasLocalStorage } from "~lib/localStorage";

type Props = {
  children: ReactElement;
};
type Auth = {
  logout: () => void;
};
const InitPreparation: FC<Props> = props => {
  const auth: Auth = useContext(AuthContext);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(true);
  const isLoading = isInitializing || isRedirecting;

  useEffect(() => {
    init(auth, setIsInitializing, setIsRedirecting);
  }, []);

  return (
    <>
      {isLoading ? (
        <PageDotsLoading className="page-dots-loading" changeColor={false} />
      ) : (
        props.children
      )}
    </>
  );
};

const init = async (
  auth: Auth,
  setIsInitializing: (isInitializing: boolean) => void,
  setIsRedirecting: (isRedirecting: boolean) => void
) => {
  await initForceLogout(auth, setIsInitializing);
  doRedirect(setIsRedirecting);
};

const initForceLogout = (
  auth: Auth,
  setIsInitializing: (isInitializing: boolean) => void
) => {
  const user = getLocalUserObject();
  const isConnectWithIndeed = user?.connectedWithIndeed;
  const loginFlag = !!user?.accountEmail;
  saveFirstVisitState(loginFlag);
  const firstVisitState = getLocalStorage(FIRST_VISIT_STATE);
  if (!isConnectWithIndeed && loginFlag && firstVisitState === "logged") {
    doForceLogout(auth.logout, setIsInitializing);
  }
  if (user?.createdDate) {
    const oneDayTimeStamp = 86400;
    const endTime = user.createdDate + oneDayTimeStamp * 2;
    const restTime = endTime - Date.now() / 1000;
    if (restTime < 0) {
      doRemoveExpiredGuestUser(auth.logout, setIsInitializing);
    }
  }
};

const doRedirect = (setIsRedirecting: (isRedirecting: boolean) => void) => {
  const user = getLocalUserObject();
  const isUser = !!(user && user.id);
  const isVerified = !!(user && user.id && user.accountEmail);
  const isIndexPage = window.location.pathname === "/";
  const needRedirectToResumeBuilder =
    isIndexPage &&
    sessionStorage?.getItem(NEED_REDIRECT_TO_RESUME_BUILDER) !== "false";
  if (needRedirectToResumeBuilder) {
    if (isUser && !isVerified) navigate("/resume/builder");
    if (isVerified) {
      localStorage.setItem(RESTORE_PATH, "/dashboard");
      navigate("/dashboard");
    }
    setTimeout(() => {
      setIsRedirecting(false);
    }, 1000);
  } else {
    setIsRedirecting(false);
  }
  sessionStorage?.setItem(NEED_REDIRECT_TO_RESUME_BUILDER, "false");
};

const doForceLogout = async (
  logout: () => void,
  setIsInitializing: (isInitializing: boolean) => void
) => {
  if (!getLocalStorage(FORCE_LOGOUT)) {
    localStorage.setItem(FORCE_LOGOUT, "true");
    setIsInitializing(true);
    await logout();
    setIsInitializing(false);
    navigate("/");
  }
};

const doRemoveExpiredGuestUser = async (
  logout: () => void,
  setIsInitializing: (isInitializing: boolean) => void
) => {
  setIsInitializing(true);
  await logout();
  setIsInitializing(false);
  navigate("/");
};

const saveFirstVisitState = (loginFlag: boolean) => {
  if (!getLocalStorage(FIRST_VISIT_STATE)) {
    const state = loginFlag ? "logged" : "notLog";
    localStorage.setItem("firstVisitState", state);
  }
};

const getLocalStorage = (prop: string) => {
  if (hasLocalStorage()) {
    const isExist = localStorage.getItem(prop);
    return isExist;
  }
};

export default InitPreparation;
