import React, { FC } from "react";

import * as styles from "./styles.module.scss";

import { getCurrentPageThemeColor } from "~src/lib/getThemeNameAndColor";
import "~css/animations/loadingGooey.scss";

type Props = {
  className?: string;
  changeColor?: boolean;
  isCoverLetter?: boolean;
};

const PageDotsLoading: FC<Props> = ({ className, changeColor }) => {
  const currentPageThemeColor = getCurrentPageThemeColor();
  const themeStyle = changeColor
    ? `${styles.dot} ${currentPageThemeColor}`
    : "";

  return (
    <div className={className}>
      <div className={`gooey`}>
        <span className={`dot ${themeStyle}`} />
        <div className="dots">
          {Array(3)
            .fill(0)
            .map((item, index) => {
              return <span key={index} className={themeStyle} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default PageDotsLoading;
