// extracted by mini-css-extract-plugin
export var aiWriterModal = "styles-module--ai-writer-modal--3797c";
export var btnCancel = "styles-module--btn-cancel--99a7b";
export var btnCancelContainer = "styles-module--btn-cancel-container--9cc3e";
export var btnConfirm = "styles-module--btn-confirm--62eb4";
export var btnConfirmContainer = "styles-module--btn-confirm-container--c733c";
export var fullScreen = "styles-module--full-screen--596e6";
export var imgContent = "styles-module--img-content--d7a5c";
export var listModal = "styles-module--list-modal--98ce6";
export var middleTitle = "styles-module--middle-title--f505a";
export var modalContainer = "styles-module--modal-container--0db97";
export var modalContent = "styles-module--modal-content--409c7";
export var modalHeader = "styles-module--modal-header--3e45d";
export var modalHeaderContainer = "styles-module--modal-header-container--ce3a1";
export var modalOverlay = "styles-module--modal-overlay--f2d25";
export var modalTitle = "styles-module--modal-title--27b39";
export var modalTitleOfAIWriter = "styles-module--modal-title-of-AI-writer--63d76";
export var modalWrapper = "styles-module--modal-wrapper--12341";
export var popUpTextarea = "styles-module--pop-up-textarea--6e55a";
export var previewHeaderPadding = "styles-module--preview-header-padding--8be3f";
export var tappedImgView = "styles-module--tapped-img-view--db161";