export const addSuffix = (suffix: string): string => {
  if (usesWebp()) {
    return `${suffix}.webp`;
  }
  return `${suffix}.png`;
};

export const transformJpgToWebp = (suffix: string): string => {
  if (usesWebp()) {
    return `${suffix}.webp`;
  }
  return `${suffix}.jpeg`;
};

export function usesWebp(): boolean {
  if (typeof navigator === "undefined") return false;
  return (
    navigator.userAgent.indexOf("Firefox") > -1 ||
    navigator.userAgent.indexOf("Chrome") > -1 ||
    navigator.userAgent.indexOf("Opera") > -1 ||
    navigator.userAgent.indexOf("Edge") > -1
  );
}
