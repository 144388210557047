/**
 * isObjectLogicEmpty
 * checks if all object is empty, or all of the properties evaluates to false
 *
 * @param {Object} obj input object
 * @returns {boolean}
 */

interface ParameterObj {
  [key: string]: any;
}

export function isObjectLogicEmpty(
  obj: ParameterObj,
  ignoreTypename = false
): boolean {
  if (!obj) return true;
  const keys = Object.keys(obj);
  for (const key of keys) {
    if ((!ignoreTypename || key !== "__typename") && obj[key]) return false;
  }
  return true;
}

const isSectionItemEmpty = (obj: ParameterObj): boolean => {
  if (!obj) return true;
  const keys = Object.keys(obj);
  let isEmpty = true;
  for (const key of keys) {
    if (!["id", "__typename", "sort"].includes(key)) {
      if (obj[key]) {
        isEmpty = false;
      }
    }
  }
  return isEmpty;
};

export const isSectionLogicEmpty = (resumeObj: ParameterObj): boolean => {
  const noDescriptionList = [
    "Awards",
    "Experience",
    "Education",
    "Skills",
    "Languages",
    "Certificates"
  ];
  // Sections with above names have no description but an array named "items".
  if (
    noDescriptionList.includes(resumeObj.name) &&
    resumeObj.title !== "customSection"
  ) {
    if (["Experience", "Education"].includes(resumeObj.name)) {
      let isItemEmpty = true;
      resumeObj.items.map(
        item => (isItemEmpty = isItemEmpty && isSectionItemEmpty(item))
      );
      return isItemEmpty;
    } else {
      return !resumeObj.items[0];
    }
  } else {
    return !resumeObj.description;
  }
};

export const removeNullValueFieldsFromObject = (
  object: ParameterObj
): ParameterObj => {
  if (typeof object === "object") {
    for (const key in object) {
      if (object[key] === undefined || object[key] === null) {
        delete object[key];
      }
    }
  }
  return object;
};

export const deepEqual = (
  a: string | ParameterObj,
  b: string | ParameterObj
): boolean => {
  if (a === b) return true;

  if (a == null || typeof a !== "object" || b == null || typeof b !== "object")
    return false;

  const keysA = Object.keys(a),
    keysB = Object.keys(b);

  if (keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false;
  }

  return true;
};

export const updateResumeSectionIDs = (resumeObj: ParameterObj): void => {
  if (resumeObj.sections) {
    for (let i = resumeObj.sections.length; i--; ) {
      if (resumeObj.sections[i].title !== "customSection") {
        resumeObj.sections[i].id = resumeObj.sections[i].title;
      }
    }
  }
};

export const convertSectionItemsToDescription = (
  resumeObj: ParameterObj
): void => {
  const sectionsNeedToBeConverted = [
    "skills",
    "languages",
    "certificates",
    "awards"
  ];
  for (const section of resumeObj.sections) {
    if (sectionsNeedToBeConverted.includes(section.title)) {
      const itemNames = [];
      section.items.forEach(element => {
        itemNames.push(element.name);
      });
      section.description = itemNames.length > 0 ? itemNames.join(", ") : "";
    }
  }
};
