import React, { FC } from "react";

import * as styles from "./styles.module.scss";

type Props = {
  className?: string;
};

const CircleLoading: FC<Props> = props => {
  const { className } = props;
  return <div className={`${styles.loadingCircle} ${className}`} />;
};

export default CircleLoading;
