import axios from "axios";
import { GraphQLError } from "graphql";
import { cloneDeep } from "lodash-es";

import { client } from "~apollo/graphql-gateway/client";
import {
  moveGuestCoverLettersToAccount,
  removeCoverLetter
} from "~apollo/graphql-gateway/coverLetterMutationActions";
import { coverLetterQueries } from "~apollo/graphql-gateway/queries/coverLetterQueries";
import { resumeQueries } from "~apollo/graphql-gateway/queries/resumeQueries";
import {
  moveGuestResumesToAccount,
  removeResume
} from "~apollo/graphql-gateway/resumeMutationActions";
import {
  MAXIMUM_COVER_LETTER_LIMIT,
  MAXIMUM_RESUME_LIMIT,
  MOVE_GUEST_RESUME_TO_ACCOUNT_ERROR
} from "~lib/constants";
import {
  GUEST_USER_LETTERS,
  GUEST_USER_RESUMES
} from "~lib/keyNamesOfLocalStorage";

axios.defaults.withCredentials = true;

const AUTH_URL = process.env.AUTH_ENDPOINT;

export async function refreshToken(): Promise<Record<any, any>> {
  // needs to throw error for promiseToObservable on client.js to work
  if (!AUTH_URL) {
    throw new Error("Auth url not defined");
  }
  const accessTokenURL = `${AUTH_URL}/getAccessToken`;
  try {
    return await axios.get(accessTokenURL);
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function verifyMagicToken(
  token: string,
  isGroupRbResumeLimitationModal1?: boolean
): Promise<
  | {
      success: boolean;
      user: any;
      moveGuestResumesResult?: boolean;
    }
  | {
      success: boolean;
      user?: undefined;
      moveGuestResumesResult?: boolean;
    }
> {
  try {
    if (!AUTH_URL) {
      throw new Error("Auth url not defined");
    }
    if (typeof token === "string") {
      const magicTokenURL = `${AUTH_URL}/usertoken/${token}`;
      const response = await axios.get(magicTokenURL);
      if (
        response.status === 200 &&
        typeof response.data === "object" &&
        response.data.userId
      ) {
        const guestUserId = response.data.guestUserId;
        let moveGuestResumesResult = null;
        if (guestUserId) {
          moveGuestResumesResult = await moveGuestResumesToRegularAccount(
            guestUserId,
            isGroupRbResumeLimitationModal1
          );
          await moveGuestCoverLettersToRegularAccount(guestUserId);
        }
        return {
          success: true,
          user: {
            id: response.data.userId,
            ...response.data
          },
          moveGuestResumesResult:
            moveGuestResumesResult === MOVE_GUEST_RESUME_TO_ACCOUNT_ERROR
        };
      }
      return { success: false };
    }
  } catch (e) {
    console.log(e);
    return { success: false };
  }
}

const moveGuestResumesToRegularAccount = async (
  guestUserId: string,
  isGroupRbResumeLimitationModal1?: boolean
) => {
  if (localStorage.getItem(GUEST_USER_RESUMES) === "0") {
    localStorage.removeItem(GUEST_USER_RESUMES);
    return;
  }
  const data = { Resume: { getAll: [] } };
  const { data: resumesData } = await client.query({
    query: resumeQueries.getAllResumes
  });
  // Note: For guest user, we will have a new epic to deal with this.
  // So keep using MAXIMUM_RESUME_LIMIT
  if (
    !isGroupRbResumeLimitationModal1 &&
    resumesData?.Resume?.getAll.length === MAXIMUM_RESUME_LIMIT
  ) {
    data.Resume = cloneDeep(resumesData.Resume);
    const earliestResume = data?.Resume?.getAll.sort(
      (resumeA, resumeB) => resumeA.updatedDate - resumeB.updatedDate
    )[0];
    await removeResume(earliestResume.id);
  }
  const result = await moveGuestResumesToAccount(guestUserId);
  if (
    isGroupRbResumeLimitationModal1 &&
    result?.graphQLErrors?.[0]?.message?.includes(
      "Cannot move guest resumes; user already has more than"
    )
  ) {
    return MOVE_GUEST_RESUME_TO_ACCOUNT_ERROR;
  }
  localStorage.removeItem(GUEST_USER_RESUMES);
};

const moveGuestCoverLettersToRegularAccount = async (guestUserId: string) => {
  if (localStorage.getItem(GUEST_USER_LETTERS) === "0") {
    localStorage.removeItem(GUEST_USER_LETTERS);
    return;
  }
  const data = { CoverLetter: { getAll: [] } };
  const { data: coverLettersData } = await client.query({
    query: coverLetterQueries.getAllCoverLetters
  });
  if (
    coverLettersData?.CoverLetter?.getAll.length === MAXIMUM_COVER_LETTER_LIMIT
  ) {
    data.CoverLetter = cloneDeep(coverLettersData.CoverLetter);
    const earliestCoverLetter = data?.CoverLetter?.getAll.sort(
      (preCoverLetter, nextCoverLetter) =>
        preCoverLetter.updatedDate - nextCoverLetter.updatedDate
    )[0];
    await removeCoverLetter(earliestCoverLetter.id);
  }
  await moveGuestCoverLettersToAccount(guestUserId);
  localStorage.removeItem(GUEST_USER_LETTERS);
};

export const logout = async (): Promise<Record<any, any>> => {
  return await axios.get(`${AUTH_URL}/logout`, { withCredentials: true });
};

export function hasAlreadyExistsErr(gqlError: {
  graphQLErrors: Array<any>[];
}): Array<any> | null {
  const graphQLErrors =
    gqlError && gqlError.graphQLErrors && gqlError.graphQLErrors.length > 0;

  return graphQLErrors
    ? gqlError.graphQLErrors.find(e => (e || {})["code"] === "ALREADY_EXISTS")
    : null;
}

export function hasNotFoundErr(gqlError: {
  graphQLErrors: Array<any>[];
}): Array<any> {
  return ((gqlError || {}).graphQLErrors || []).find(
    e => (e || {})["code"] === "NOT_FOUND"
  );
}

export function hasAuthorizationFailedErr(gqlError: {
  graphQLErrors: readonly GraphQLError[];
}): GraphQLError {
  return ((gqlError || {}).graphQLErrors || []).find(
    e => (e || {})["code"] === "UNAUTHENTICATED"
  );
}

export function hasInvalidArgumentsErr(gqlError: {
  graphQLErrors: Array<any>[];
}): Array<any> {
  return ((gqlError || {}).graphQLErrors || []).find(
    e => (e || {})["code"] === "INVALID_ARGUMENT"
  );
}

export function extractErrorDetail(
  gqlError: {
    graphQLErrors: Array<any>[];
  },
  code = "INVALID_ARGUMENT"
): Array<any> {
  const err = ((gqlError || {}).graphQLErrors || []).find(
    e => (e || {})["code"] === code
  );
  return transformErrorDetails(err && err["details"]);
}

function transformErrorDetails(details) {
  const keys = Object.keys(details || {});
  return keys
    .map(key => {
      return details[key] || "";
    })
    .reduce((sum, cur) => {
      return sum + "\n" + cur;
    }, "");
}
