import axios, { AxiosResponse } from "axios";

export async function getGRP(
  testName: string,
  userId: string
): Promise<AxiosResponse<any>> {
  const unBiasEndPoint: string =
    process.env.UNBIAS_ENDPOINT || "https://www.resume.com/groups";

  return axios.get(`${unBiasEndPoint}?test_name=${testName}&user_id=${userId}`);
}
