import {
  getLocalStorageFirstNamePlaceholder,
  getLocalStorageLastNamePlaceholder,
  setLocalStorageFirstNamePlaceholder,
  setLocalStorageLastNamePlaceholder
} from "~lib/localStorage";

export const FIRST_NAME_PLACEHOLDERS: string[] = [
  "Anonymous",
  "Unnamed",
  "Nameless",
  "Incognito",
  "Unidentified",
  "Unknown",
  "Unspecified",
  "Unclaimed",
  "Mystery"
];

export const LAST_NAME_PLACEHOLDERS: string[] = [
  "Bird",
  "Cat",
  "Dog",
  "Fox",
  "Kangaroo",
  "Penguin",
  "Tiger",
  "Wolf",
  "Giraffe"
];

export const getFirstNamePlaceholders = (isVerified: boolean): string => {
  let firstNamePlaceholder = "First Name";
  const randomFirstNamePlaceholder = getLocalStorageFirstNamePlaceholder();
  if (!isVerified) {
    firstNamePlaceholder =
      randomFirstNamePlaceholder ||
      FIRST_NAME_PLACEHOLDERS[
        Math.floor(Math.random() * FIRST_NAME_PLACEHOLDERS.length)
      ];
    setLocalStorageFirstNamePlaceholder(firstNamePlaceholder);
  }
  return firstNamePlaceholder;
};

export const getLastNamePlaceholders = (isVerified: boolean): string => {
  let lastNamePlaceholder = "Last Name";
  const randomLastNamePlaceholder = getLocalStorageLastNamePlaceholder();
  if (!isVerified) {
    lastNamePlaceholder =
      randomLastNamePlaceholder ||
      LAST_NAME_PLACEHOLDERS[
        Math.floor(Math.random() * LAST_NAME_PLACEHOLDERS.length)
      ];
    setLocalStorageLastNamePlaceholder(lastNamePlaceholder);
  }
  return lastNamePlaceholder;
};

export const returnResumePlaceholders = (
  isVerified: boolean,
  id: string
): any => {
  return {
    contactDetails: {
      email: "Email@example.com",
      phone: "Phone Number",
      firstName: getFirstNamePlaceholders(isVerified),
      lastName: getLastNamePlaceholders(isVerified),
      address: null,
      city: "City",
      zip: "Zip",
      country: "Country",
      state: "State",
      jobCategory: "JobCategory"
    },
    summary: !id
      ? ""
      : {
          id: "summary",
          title: "summary",
          name: "Summary",
          description:
            "Enter a brief description of your professional background. You can choose to highlight specific skills, knowledge or industry experience."
        },
    employment: {
      id: "employment",
      title: "employment",
      name: "Experience",
      items: [
        {
          id: "item-employment-0",
          title: "Sales Representative",
          company: "Company A",
          city: "Location",
          startDate: "Start and end date",
          description: "Enter key responsibilities and accomplishments."
        },
        {
          id: "item-employment-1",
          title: "Sales Associate",
          company: "Company B",
          city: "Location",
          startDate: "Start and end date",
          description:
            "Show that you create value with your work by listing your responsibilities and quantifiable achievements in the experience."
        },
        {
          id: "item-employment-2",
          title: "Sales Assistant",
          company: "Company C",
          city: "Location",
          startDate: "Start and end date",
          description:
            "Use AI to assist you in creating a work description using your experience and desired job information."
        }
      ]
    },
    skills: {
      id: "skills",
      title: "skills",
      name: "Skills",
      description: "List 3-4 special, work-related, talents skills.",
      items: [
        {
          id: "item-skills-0",
          name: "List 3-4 special, work-related, talents skills."
        }
      ]
    },
    education: {
      id: "education",
      title: "education",
      name: "Education",
      items: [
        {
          id: "item-education-0",
          qualification: "Field of study",
          school: "Graduated school",
          city: "Location",
          endDate: "Graduation Date",
          description:
            "Enter any colleges, universities, or training programs that you have attended."
        }
      ]
    },
    languages: {
      id: "languages",
      title: "languages",
      name: "Languages",
      description: "Enter any languages you are proficient in.",
      items: [
        {
          id: "item-languages-0",
          name: "Enter any languages you are proficient in."
        }
      ]
    },
    certificates: {
      id: "certificates",
      title: "certificates",
      name: "Certificates",
      description:
        "Show your certificates, licenses, and training in your field.",
      items: [
        {
          id: "item-certificates-0",
          name: "Show your certificates, licenses, and training in your field."
        }
      ]
    },
    awards: {
      id: "awards",
      title: "awards",
      name: "Awards",
      description: "Mention any prizes, honors, contests etc.",
      items: [
        {
          id: "item-awards-0",
          name: "Mention any prizes, honors, contests etc."
        }
      ]
    }
  };
};
